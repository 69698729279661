.success-setup h1{
padding-top: 20px;
font-weight: 500;
font-size: 2rem;
padding-bottom: 20px;
}

.success-setup .store-url{
width: 40%;
background-color: white;
margin: 0 auto;
height: 40px;
border-radius: 10px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.success-setup .store-url h3{
    padding-top: 10px;
}

.success-setup .share-url{
    padding-top: 30px;
}
.success-setup .share-url button{
    width: 200px;
    height: auto;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.4rem;
    font-family: inherit;
}

.success-setup .share-url button i{
    color: #0FA958;
    font-size: 1.5rem;
    padding-right: 10px;
}

button.proceed-dashboard-btn{
 margin-top: 20px;
 width: 270px;
 height: 40px;
 outline: none;
 border: none;
 border-radius: 10px;
 color: white;
 font-family: inherit;
 font-size: 1rem;
 cursor: pointer;
 background-color: #0FA958;
}
    