.dash_nav_container {
    height: 100vh;
    position: relative;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    z-index: 10000;
    transition: width 1s ease-in-out;
}
.dash_nav_container .dash_nav_contain {
    width: 88%;
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    box-shadow: 4px 0px 5px 0 rgba(0, 0, 0, 0.2);
}
.dash_nav_container .dash_nav_contain_full {
    width: 88%;
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    box-shadow: 4px 0px 5px 0 rgba(0, 0, 0, 0.2);
}
.dash_nav_contain .dash_nav_content, .dash_nav_contain_full .dash_nav_content {
    width: 90%;
   
    box-sizing: border-box;
    height: 100%;
}
.dash_nav_content .dash_nav_top {
    flex: 1;
    width: 100%;
    overflow-x: auto;
    height: 100px;
  
}
.dash_nav_top {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.dash_nav_content .dash_navlists {
    flex: 3;
    width: 100%;
}
.dash_nav_content .dash_nav_bottom {
    flex: 1;
    float: bottom;
    position: absolute;
    bottom: 0px;
    
    width: 85%;
}
.dash_nav_container .slider {
    float: right;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    top: 20px;
    position: absolute;
    z-index: 10000;
    right: 3px;
    z-index: 1000000;
    cursor: pointer;
    border-radius: 50%;
    border-width: 5px;
    border: .5px solid #e2e8f0;
    background-color: #ffffff;
    box-shadow: 2px 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.dash_nav_top .brand {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    box-sizing: border-box;
    margin-left: -15px;
}
.brand > img {
    width: 25px;
    height: 25px;
}
.brand > span {
    font-size: 19px;
    font-weight: bold;
    padding-left: 4px;
    color: #34A853;
}
.dash_navlists > ul, .store_flex > ul {
    list-style: none;
}
.dash_navlists > ul > li {
    height: auto;
    padding: 10px;
    
}
.dash_nav_bottom .dash_nav_logout {
    width: 100%;
}
li {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

li .store_flex {
    width: 80%;
}
.store_flex {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    height: auto;
}

.store_flex .dropdown__List__Hover {
    background-color: #ffffff;
    position: absolute;
    right: -120%;
    top: 50%;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 18% 30%;
    z-index: 999;
}

.store_flex .dropdown__List__Hover a:hover,
.store_flex .dropdown__List__Hover a:focus {
    color: #34A853;
}

li .sidebar_list {
    width: 80%;
    height: 40px;
    padding-left: 0px;
    
}
.dash_nav_logout .dash_logout {
    padding-left: 20px;
    width: 90%;
    height: 40px;
    
}
.store_flex ul li {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
li .store_dropdown {
    width: 70%;
    font-size: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
}
.sidebar_list, .dash_logout {
    display: flex;  
    align-items: center;
}

.sidebar_list img, .dash_logout img {
    width: 20px;
    height: 20px;
    
}

.sidebar_list span, .dash_logout span {
    font-size: 15px;
    padding-left: 15px;
}
.sidebar_list:hover span, .dash_logout:hover span {
    color: #34A853; 
}
.sidebar_list:hover .sidebar_navlist_img, .dash_logout:hover .sidebar_navlist_img {
    filter: invert(48%) sepia(80%) saturate(1000%) hue-rotate(86deg) brightness(50%);
}

.dashboard__Icon{
    width: 20px;
    height: 20px;
    color: #545454;
}

@media screen and (max-width: 500px) {
    li .sidebar_list {
        padding-left: -8px;
    }
}
@media screen and (max-width: 500px) {
    .dash_nav_container {
        height: 130px;
        width: 100vw;
        overflow: visible;
        position: fixed;
        box-sizing: border-box;
        bottom: 0%;
        
        display: flex;
        justify-content: flex-end;
        backdrop-filter: blur(-20px) sepia(57%);
        z-index: 1000000000000;
    }
    .dash_nav_container .dash_nav_contain, .dash_nav_container .dash_nav_contain_full {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: none;
        box-shadow: none;
        
        z-index: 10000000000000;
    }
    .dash_nav_container .slider {
        display: none;
    }
    .dash_nav_contain .dash_nav_content, .dash_nav_contain_full .dash_nav_content {
        width: 100%;
        height: 80px;
        bottom: 0px;
        background-color: #ffffff;
        box-shadow: 4px 0px 5px 0 rgba(0, 0, 0, 0.2);
        
        
    }
    .dash_nav_top, .dash_nav_bottom {
        display: none;
    }
    .dash_nav_content .dash_navlists {
        width: 100%;
    }
    .dash_navlists > ul {
        width: 100%;
        
        height: auto;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        list-style: none;
    } 
    ul > li {
        height: 60px;
        width: 19%;
        display: flex;
        
        justify-content: space-around;
        align-items: center;
    }
    li .sidebar_list {
        padding-left: 15px;
    }
    .sidebar_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        justify-content: center;
        align-content: space-around;
    }
    .sidebar_list img, .dashboard__Icon  {
        width: 15px;
        height: 15px;
    }
    .sidebar_list span {
        font-size: 13px;
        padding-top: 10px;
        padding-left: 0px;
    }
    li:nth-child(4) span, li:nth-child(5) span {
        font-size: 11px;
    }
    .dropdown_lists {
        z-index: 100000000000000;
    }
    li .store_flex .dropdown_lists {
        width: 80px;
        height: 60px;
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        backdrop-filter: blur(-2px) sepia(50%);
        
        bottom: 65px;
        background-color: #ffffff;
        border-radius: 10px 10px 10px 0px;
        box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.2), -2px 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
    li .store_flex ul li {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 399px) {
    ul > li {
        height: 60px;
        width: 15%;
        display: flex;
        
        justify-content: space-around;
        align-items: center;
    }
    li .sidebar_list {
        width: 100%;
        padding-left: 0px;
    }
    .store_flex {
        padding-left: 0px;
    }
    .sidebar_list span {
        font-size: 11px;
    }
}