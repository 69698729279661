/*filter drop down styles**/

.order-container .status__Dropdown__Select {
  width: max-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0px;
  margin-bottom: 15px;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
}

.order-container .status__Dropdown__Select .dropdown__Icon{
  margin-left: 3%;
}

.order-container .status__Dropdown__Select .dropdown__Icon.invert{
  transform: rotate(180deg);
}

.order-container .status__Dropdown__Options {
  margin-left: auto;
  text-align: center;
  position: absolute;
  right: 0;
  top: 120%;
  background-color: #fff;
  z-index: 2;
  padding: 15px 10px;
  box-sizing: border-box;
  box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 9rem;
}

.order-container .status__Dropdown__Options div {
  cursor: pointer;
  margin: 10% 0;
  padding: 5% 0;
  width: 100%;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
}

.order-container .status__Dropdown__Options div:hover,
.order-container .status__Dropdown__Options div:focus {
  background-color: #B9F0D2;
}
/*end**/

/* table */
.order-container table {
    border-collapse: collapse;
    font-size: .9rem;
    width: 100%;
    /* height: 100%; */
  }

  .order-container {
    height: auto;
    padding-top: 20px;
    /* width: 100%; */
    /* overflow-x: scroll; */
  }

  .order-container h4{
    color: #2c3e50;
    font-weight: 200;
    margin-bottom: -20px;
  }


  .order-container  table th, .order-container  table td {
    text-align: left;
    padding: 8px;
  }

.order-container  table td select{
    font-family: inherit;
}
  
.order-container  table tr:nth-child(even){background-color: #f2f2f2}
  

.order-container  table tr:hover{
  background-color: #e8e6e64d;
  cursor: pointer;
}
.order-container  table th {
    background-color:#34A853;
    color: white;
  }

  .order-container  table th.serial-number {
    border-top-left-radius: 20px;
  }

  .order-container  table th.table-prducts-price {
    border-top-right-radius: 20px;
  }
  
  .order-container  table td a:hover{
    text-decoration: underline;
  }

/*footer styles*/
.tableFooterX {
    background-color: transparent;
      padding: 8px 0px;
      width: 100%;
      font-weight: 500;
      text-align: left;
      font-size: 16px;
      color: #2c3e50;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .buttonX {
      border: none;
      padding: 7px 14px;
      border-radius: 10px;
      cursor: pointer;
      margin-right: 4px;
      margin-left: 4px;
    }
    
    .activeButtonX {
      color: white;
      background-color:#02310e;
    }
    
    .inactiveButtonX {
      color: #2c3e50;
      background: #f9f9f9;
    }


    /**order details page */
    .order-details-container{
      margin-left: 20px;
      margin-top: 50px;
    }

    .order-details-container h2{
      font-weight: 300;
      color: #354661;
    }

    .order-details-container button.print-order{
      width: 100px;
      height: 30px;
      border-radius: 10px;
      font-family: inherit;
      cursor: pointer;
      background-color: white;
      border: 1px solid #0FA958;
      margin-top: 10px;
    }

    .order-details-container button.update-order{
      width: 200px;
      height: 30px;
      cursor: pointer;
      border-radius: 10px;
      font-family: inherit;
      background-color: white;
      border: 1px solid #0FA958;
      margin-top: 10px;
    }

   .order-details-content{
    margin-top: 20px;
    display: flex;
    width: 97%;
    flex-direction: row;
    justify-content: space-between;
    /* min-height: 60vh; */
    min-height: 100%;
    border-radius: 25px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25);
    /* padding-bottom: 30px; */
   }
   .order-details-section-1{
    margin-top: 30px;
    margin-left: 60px;
    padding-right: 40px;
    height: 300px;
   }

   .order-details-section-1 h3{
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 400;
   }

   .order-details-section-1 h5{
    margin-top: 10px;
    font-weight: 300;
   }

   .affiliate-details{
    margin-top: 30px;
  }
  
  .affiliate-details img{
  height: 30px;
  margin-top: 10px;
  }
  
  .affiliate-details h5{
    margin-top: -25px;
    margin-left: 40px;
  }

   .order-details-divider{
    margin-top: 35px;
    width: 0px;
    /* margin-right: -190px; */
    height: 50vh;
    background-color: #BEBEBE;  
   }

   .order-details-section-2{
    margin-top: 30px;
    width: 50%;
    height: 350px;
    padding-left: 20px;
    border-left: 2px solid #BEBEBE;
   }

   .order-details-section-2-first{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width:80%;
   }

   .order-details-section-2 h3{
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 10px;
   }

   .order-details-section-2 select{
    width: 200px;
    border: 1px solid #0FA958;
    height: 40px;
    border-radius: 10px;
    font-family: inherit;
   }

   .order-product-container{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    left:0px; 

  
  }

   .order-details-section-2 .order-products{
display: flex;
flex-wrap: wrap;
min-width: 80%;
position: absolute;
left: 0px;
height: auto;
   }


   .order-details-section-2 .order-product{
    width: 30%;
    margin: 0px;
    box-sizing: border-box;
    border-radius:10px;
    transition: .4s;
    background-color: white;
   }

   .order-details-section-2 img{
    width: 100%;
}

   .order-product img{
    width: 80px; 
    height: 80px;
    object-fit: contain;
padding:10px;
margin-left: -8px;
}

.order-product-detail{
  margin-top: -10px;
  font-weight: 400;
}

.order-product-detail h5{
  font-weight: 400;
}

@media screen and (max-width:1000px) {
  .order-details-section-2 .order-product{
    margin: 5px;
  }

  .order-details-section-2{
    height: 400px;
  }
}

@media screen and (max-width:800px) {
  .order-details-content{
    flex-direction: column;
  }

  .order-details-divider{
   width: 80%;
   height: 2px;
   /* margin-top: -150px; */
   margin-top: 70px;
   margin-bottom: -25px;
   margin-left: auto;
   margin-right: auto;
  }

  .order-details-section-2-first{
    width: auto;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 60%;
    margin-right: 0%;
  }
  .order-details-section-2 .order-products{
  min-width: 170%;
 justify-content: space-evenly;
  }

  .order-details-section-2 .order-product{
    margin: 5px;
  }

   .order-details-section-2{
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0%;
    margin-right: 0%;
    height: 500px;
    border-left: none;
   }

   .order-details-section-2 h3{
    font-size: 1rem;
    font-weight: 300;
    padding-right: 30px;
  
   }
}

textarea.order-cancel-reason{
  padding-left: 10px;
  margin-top: 20px;
width: 200px;
height:50px;
padding-top: 5px;
border-radius: 10px;
border: 1px solid #0FA958;
font-family: inherit;
}


@media print {
  .dash_nav_container, 
  .affiliate_content .dash_top,
  .order-details-container .print__Btn,
  .order-details-container h2 .default__Back__Btn,
  .order-details-container button.update-order,
  .order-details-section-2-first select {
    display: none;
  }

  .order-details-container{
    width: 100%;
  }

  .order-details-section-2 .order-product{
    width: 50%;
  }
}



    
    /****/
    