body{
    overflow-x: hidden;
}

.home-banner{
background: #F9F8F8;
height: 450px;
margin-top: -8px;
}
.home-banner .home-banner-img img{
    position: absolute;
    right: 3rem;
    top: 6rem;
    max-width: 28rem;
    height: auto;
}

.home-banner .home-bannner-text h1{
    position: relative;
    top: 80px;
    font-size: 3.3rem;
    font-weight: bolder;
    max-width: 50%;
    left: 4rem;
    line-height: 65px;
    color: #283A57;
}

.home-banner .home-bannner-text p{
    color: #283A57;
    max-width: 50%;
    font-size: 1rem;
    margin-top: 7%;
    margin-left: 4rem;
}

.home-banner .home-bannner-text h1 span{
    color: rgba(52, 168, 83, 1);
}

.home-banner-register{
    position: relative;
 top:3rem;
 left:4rem;
}

.home-banner-register input{
    /* padding: 15px 100px 15px 20px; */
    width: 350px;
    height: 3.3rem;
    border-radius: 20px;
    outline: none;
    border: none;
    background-color: rgba(196, 196, 196, 0.12); 
    font-weight: 600;   
    font-family: inherit;
    padding-left: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-banner-register input::placeholder{
    font-weight: 598;
    position: relative;
}

.home-banner-register button{
outline: none;
border: none;
background: rgba(52, 168, 83, 1);
color: white;
font-family: inherit;
font-weight: 600;
width: 150px;
height: 3.3rem;
border-radius: 20px;
position: relative;
left: -55px;
cursor: pointer;
}

 .box_2{
    margin-top: 5rem;
    height: 7rem;
 }
.box_2 h1{
    color: #283a57;
    font-size: 2.5rem;
    padding-bottom: 1rem;
}

.box_2 p{
line-height: 25px;    
margin: 0 auto;
width: 50%;
}

.box_3{
    background-color: #79E3AA85;
    height: 20rem;
    padding: 3rem 0rem 3rem;
}

.box_3_section_1{
    margin-left: 4rem;
    padding-top: 30px;
}
.box_3 h1, .box_4 h1{
    color: #283a57;
    font-size: 2.5rem;
    /* padding-top: 80px; */
    width: 40px;
}

.box_3 p, .box_4 p{
    padding-top: 20px;
    width: 38rem;
    font-size: 1rem;
    line-height: 25px;
}

.box_3 button.start_selling, .box_4 button.start_earning{
    background: #34A853;
    width: 15vw;
    border-radius: 30px;
    height: 40px;
    outline: none;
    border: none;
    color: white;
    font-family: inherit;
    font-size: 0.9rem;
    cursor: pointer;
}

.box_3 .box_3_section_2{
    position: absolute;
    right: 100px;
    top: 760px;
}

.box_3 .box_3_section_2 img{
    max-width: 31rem;
    margin-top: 2rem;
}

.display_btn_1, .display_btn_2{
    width: 5vw;
    height: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}
.display_btn_2{
    background-color: #283A57;
}

.display_btn_1{
    background-color: #34A853;
}

.box_4{
    padding-top: 20px;
}

.box_4_section_1{
    margin-left: 4rem;
}

.box_4_section_1 img{
    padding-top: 2rem;
    max-width: 17rem;
    margin-bottom: 3rem;
}

.box_4_section_2{
    position: absolute;
    top:1220px;
    right: 80px;
}

.box_5{
    background-color: #F9F8F8;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
.box_5_content{
    display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
 
}

.box_5 h1{
    color: #283A57;
    font-size: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.box_5_container{
    display: flex;
    /* width: 1040px; */
    width: 75%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box_5_card {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
    padding-bottom: 20px;
  }

  .box_5_card img{
    position: relative;
    right: 6.5rem;
    top: 1rem;
    width: 12%;
  }

  .box_5_card h3{
    font-size: 1.3rem;
    text-align: left;
    margin: 2rem;
  }

   h3.card_1_title,  h3.card_2_title{
/* position: relative;
top: 20px;
right: 85px; */
  } 

  h3.card_3_title{
    /* position: relative;
top: 20px;
right: 10px; */
  }
  
  .box_5_card p{
    margin: 2rem;
    text-align: left;
    line-height: 1.5rem;  }
  
 


.box_6{
    background-color: #283A57;
    /* height: 200px; */
    height: auto;
}

.box_6 h1{
 color: white;
}

.box_6 section{
    min-height: 100vh;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box_6 section h1.title{
    font-size:2.5rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
}

.box_6 section .faq{
    max-width: 700px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #fff;
    cursor: pointer;
}

.box_6 section .question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
}


.box_6 section .question h3{
    font-size: 1.5rem;
    color: white;
    font-weight: 300;
}

.box_6 section .question i{
    /* padding-left: 50px; */
   color: white;
}

.box_6 section .answer p{
padding-top: 1rem;
line-height: 1.6;
font-size: 1.2rem;
color: white;
}

.box_6 section .answer{
    max-height: 0;
overflow: hidden;
animation: fade 1s ease-in-out;
transition: max-height .3s ease;
}

@keyframes fade {
    from{
        opacity: 0;
        transform: translateY(-10px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
}

/**end of faq****/

.box_7{
    background-color: #B9F0D2;
    height: 19rem;
}

.box_7 h1{
    padding-top: 6rem;
    color: #283A57;
    width: 47%;
    margin: 0 auto;
    letter-spacing: 2px;
}

.box_7 button{
    width: 20vw;
    height: 50px;
    border-radius: 100px;
    outline: none;
    border: none;
    background-color:#34A853;
    font-size: 1.5rem;
    color: white;
    font-family: inherit;
}