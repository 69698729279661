/* table */
.recent-sales-table table {
    border-collapse: collapse;
    font-size: .9rem;
    width: 100%;
    /* height: 100%; */
  }

  .recent-sales-table {
    overflow-x: scroll;
  }


  .recent-sales-table  table th, .recent-sales-table  table td {
    text-align: left;
    padding: 8px;
  }

.recent-sales-table  table td select{
    font-family: inherit;
}
  
.recent-sales-table  table tr:nth-child(even){background-color: #ececec}
  

.recent-sales-table  table tr:hover{
  background-color: #e8e6e64d;
  cursor: pointer;
}
.recent-sales-table  table th {
    background-color:#34A853;
    color: white;
  }

  .recent-sales-table  table th.serial-number {
    border-top-left-radius: 20px;
  }

  .recent-sales-table  table th.table-prducts-price {
    border-top-right-radius: 20px;
  }
  
  .recent-sales-table  table td a:hover{
    text-decoration: underline;
  }

/*footer styles*/
.tableFooterX {
    background-color: transparent;
      padding: 8px 0px;
      width: 100%;
      font-weight: 500;
      text-align: left;
      font-size: 16px;
      color: #2c3e50;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .buttonX {
      border: none;
      padding: 7px 14px;
      border-radius: 10px;
      cursor: pointer;
      margin-right: 4px;
      margin-left: 4px;
    }
    
    .activeButtonX {
      color: white;
      background-color:#02310e;
    }
    
    .inactiveButtonX {
      color: #2c3e50;
      background: #f9f9f9;
    }
    
    