.login_content{
          position: absolute;
  left: 100px;
}

.login_content .login_input{
    position: relative;
    top:60px;
}

.login_input [type=checkbox]{
 transform: translateY(150px);
 background-color: rgba(224, 219, 219, 0.36);

}


.login_input label{
 position: relative;
 top: 150px;
 left:5px;
}

.login_content button{
    position: relative;
    top:230px;
     outline: none;
    border: none;
    background-color: #34A853;
     width:400px;
     height:37px;
     position: relative;
     bottom: -18px;
    border-radius: 10px;
    color: white;
    text-align: center;
    cursor: pointer;
}


/*global styles*/
button:hover{
    opacity: .9;
}
/*end of global styles*/

.login_content button span{
    position: relative;
    bottom: 2px;
    left:-10px;
      font-size: 20px;
    letter-spacing: 2px;
} 


.login_content button img{
    transform: translate(-10px,2px);
    height: 20px;
}

.login_content .proceed_signup{
    position: relative;
    top:250px;   
}

 .login_content .proceed_signup a{
    color: black;
}
