.business__Analytics__Container{
    box-sizing: border-box;
    width: 100%;
    margin-top: 4%;
}

.business__Analytics__Container > *{
    padding-left: 2%;
}

.business__Analytics__Container .business__Analytics__Intro{
    margin: 4% auto;
}

.business__Analytics__Container .business__Analytics__Intro > * {
    display: inline-block;
    width: calc((100% - 15% - (3.5% * 2)) / 3);
    margin-right: 3.5%;
}

.business__Analytics__Container .business__Analytics__Intro > *:nth-child(3n + 3) {
    margin-right: 0;
}


.business__Analytics__Container .dark__Blue__Color {
    color: #283A57 !important;
}


.business__Analytics__Sales__Overview .charts__Container{
    padding-right: 3%;
    display: flex;
    justify-content: space-around;
    margin: 5% 0;
}

.business__Analytics__Sales__Overview.first .charts__Container .chart__Container{
    display: block;
    width: 100%;
}

.business__Analytics__Sales__Overview.first .overview__Intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97.4%;
    position: relative;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select{
    background-color: transparent;
    cursor: pointer;
    padding: 1.5% 2%;
    transition: 0.2s ease-in-out;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container{
    position: absolute;
    right: 0;
    bottom: -470%;
    box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.25);
    padding: 1.5%;
    width: 10rem;
    background-color: #fff;
    border-radius: 10px;
    animation: fadeIn 0.4s ease-in-out;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show {
    bottom: -480%;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form {
    animation: fadeIn 0.15s ease-in-out;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form > input[type=date] { 
    border: none;
    border: 1px solid #B9F0D2;
    font-family: 'Comfortaa', sans-serif;
    margin: 4% auto;
    padding: 2%;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form > input[type=date]::-webkit-datetime-edit { 
    padding: 1em; 
}
.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form > input[type=date]::-webkit-inner-spin-button { 
    display: none; 
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form .form__Icon{
    display: block;
    margin-bottom: 18%;
    color: #283A57;
    cursor: pointer;
    position: relative;
    left: -3%;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form label{
    display: block;
    margin: 8% 0 2%;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Option, 
.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button{
    margin: 2% auto;
    padding: 6%;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Option:hover,
.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Option:focus,
.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button{
    background-color: #B9F0D2;
    border-radius: 10px;
    cursor: pointer;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button{
    border: none;
    font-family: 'Comfortaa', sans-serif;
    width: 100%;
    font-size: 15px;
    margin-top: 10%;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button:hover,
.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button:focus{
    filter: opacity(0.8);
    transition: 0.35s ease-in-out;
}

.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button[disabled],
.business__Analytics__Sales__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button:disabled{
    cursor: not-allowed !important;
    filter: opacity(1);
}

.business__Analytics__Sales__Overview.first .overview__Intro .dropdown__Icon{
    position: absolute;
    right: 0;
    color: #283A57;
    transition: 0.2s ease-in-out;
    transform: rotate(0deg);
}

.business__Analytics__Sales__Overview.first .overview__Intro .dropdown__Icon.invert{
    transform: rotate(180deg);
}

.business__Analytics__Sales__Overview .chart__Container{
    width: 30%;
}

.business__Analytics__Product__Preview{
    padding: 4% 0;
    background-color: #fff;
    box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.25);
    width: 45%;
    margin: 5% auto;
    border-radius: 10px;
    position: relative;
}

.business__Analytics__Container img{
    max-width: 100%;
    width: 100%;
}

.business__Analytics__Product__Preview .stats__Count{
    position: absolute;
    animation: fadeIn 0.15s ease-in-out;
    background-color: #B9F0D2;
    padding: 1%;
    border-radius: 10px;
    color: #fff;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
    width: 150px;
    visibility: hidden;
}

.business__Analytics__Product__Preview .stats__Count::before, 
.business__Analytics__Product__Preview .stats__Count::after{
    content: "";
    height: 20px;
    width: 4px;
    background-color: #B9F0D2;
    color: #B9F0D2;
    position: absolute;
    bottom: -24%;
}

.business__Analytics__Product__Preview .stats__Count::before{
    transform: rotate(-45deg);
    left: 8%;
}

.business__Analytics__Product__Preview .stats__Count::after{
    transform: rotate(45deg);
    left: calc(8% + 10px);
}

.business__Analytics__Top__Product .business__Analytics__Product__Preview .product__Details,
.business__Analytics__Top__Product .business__Analytics__Product__Preview .stats__Count__Static{
    width: 96%;
    margin: 0 auto;
}

.business__Analytics__Top__Product .business__Analytics__Product__Preview .product__Details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Comfortaa', cursive;
    margin-top: 3%;
    margin-bottom: 4%;
}

.business__Analytics__Top__Product .business__Analytics__Product__Preview .product__Details .product__Title,
.business__Analytics__Top__Product .business__Analytics__Product__Preview .product__Details .product__Price,
.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Details .affliate__Display_Name{
    font-size: 22px;
    line-height: 16px;
}

.business__Analytics__Top__Product .business__Analytics__Product__Preview .product__Details .product__Price{
    font-weight: 700;
}

.business__Analytics__Popular__Product{
    margin-bottom: 7%;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview{
    border-radius: 10px;
    box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.25);
    width: 34.4rem;
    margin: 4% auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4% 1.5%;
    position: relative;
    transition: 0.35s ease-in-out;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview::before {
    content: '';
    position: absolute;
    top: -8.6%;
    left: calc(34.4rem * -0.194);
    border-left: calc(34.4rem * 0.3 ) solid transparent;
    border-right: calc(34.4rem * 0.3 ) solid transparent;
    border-bottom: calc(34.4rem * 0.3 ) solid #B9F0D2;
    transform: rotate(-45deg);
    z-index: 0;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Profile__Image {
    width: 45%;
    z-index: 2;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Profile__Image img{
    border-radius: 50%;
    width: 80%;
    height: 11rem;
    object-fit: cover;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Details .affliate__Display_Name{
    font-weight: 700;
    margin-bottom: 6%;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Details .affliate__Username{
    font-size: 14px;
    margin-bottom: 20%;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Details a{
    color: gray;
    transition: 0.3s ease-in-out;
    font-size: 14px;
}

.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Details a:hover, 
.business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Details a:focus {
    color: #B9F0D2;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .business__Analytics__Container .business__Analytics__Intro > * {
        width: calc((100% - 17% - (2.5% * 2)) / 3);
        margin-right: 2.5%;
    }

    .business__Analytics__Product__Preview,
    .business__Analytics__Container .business__Analytics__Intro,
    .business__Analytics__Sales__Overview .charts__Container{
        margin-bottom: 7%;
    }

    .business__Analytics__Sales__Overview .charts__Container{
        padding-right: 4.8%;
    }

    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview{
        width: 26.4rem;
        margin: 6% auto;
    }

    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview::before {
        top: -8.6%;
        left: calc(29.4rem * -0.194);
        border-left: calc(29.4rem * 0.3 ) solid transparent;
        border-right: calc(29.4rem * 0.3 ) solid transparent;
        border-bottom: calc(29.4rem * 0.3 ) solid #B9F0D2;
    }

    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Profile__Image,
    .business__Analytics__Product__Preview {
        width: 55%;
    }

    .business__Analytics__Top__Product .business__Analytics__Product__Preview .product__Details{
        margin-bottom: 6%;
    }

}

@media screen and (max-width: 767.98px) {
    .business__Analytics__Container > *{
        padding-left: 5%;
    }
    .business__Analytics__Container .business__Analytics__Intro {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        height: 200px;
        white-space: nowrap;
    }
    .business__Analytics__Container .business__Analytics__Intro > * {
        width: 70%;
        margin-top: 4%;
    }
    .business__Analytics__Container .business__Analytics__Intro > *:nth-child(3n + 3) {
        margin-right: 6%;
    }
    .business__Analytics__Sales__Overview .charts__Container{
        flex-direction: column;
        justify-content: center;
    }
    .business__Analytics__Sales__Overview .charts__Container .chart__Container{
        width: 60%;
        margin: 4% auto;
    }
    .business__Analytics__Sales__Overview.first{
        margin-bottom: 9%;
    }
    .business__Analytics__Sales__Overview.first .charts__Container{
        overflow-x: scroll;
    }
    .business__Analytics__Sales__Overview.first .charts__Container .chart__Container{
        width: 170%;
    }
    .business__Analytics__Popular__Product{
        margin-bottom: 40%;
    }
    .business__Analytics__Product__Preview{
        width: 60%;
        margin-bottom: 10%;
    }
    .business__Analytics__Top__Product h3, 
    .business__Analytics__Product__Preview h3{
        width: 80%;
        line-height: 33px;
        margin-top: 8%;
    }
    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview{
        flex-direction: column;
        width: 60%;
        margin-top: 12%;
        margin-bottom: 40%;
        padding-bottom: 20%;
    }
    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Profile__Image{
        width: 60%;
        margin-bottom: 10%;
    }
    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview .affliate__Profile__Image img{
        width: 100%;
    }
    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview::before {
        top: 0;
        left: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        height: 35%;
        background-color: #B9F0D2;
        transform: rotate(0);
    }
}

@media screen and (max-width: 400px) {
    .business__Analytics__Top__Affliate .business__Analytics__Affliate__Preview{
        width: 90%;
    }
    .business__Analytics__Product__Preview{
        width: 90%;
        margin-bottom: 15%;
    }
}
