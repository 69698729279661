.business__Wallet__Intro,
.business__Wallet__Transactions {
    width: 95.2%;
}

.business__Wallet__Intro h3{
    color: #354661;
}

.business__Wallet__Intro,
.business__Wallet__Intro .update__Btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.business__Wallet__Intro .title__Text{
    font-size: 1.25rem;
}

.business__Wallet__Intro .update__Btn {
    border: none;
    outline: none;
    border-radius: 10px;
    font-family: 'Comfortaa', cursive;
    transition: 0.35s ease-in-out;
    color: #fff;
    padding: 1.5% 2%;
    background-color: #34A853;
    cursor: pointer;
    width: max-content;
}

.business__Wallet__Intro .update__Btn .update__Account__Icon{
    font-size: 1.2rem;
}

.business__Wallet__Intro .update__Btn span{
    margin-left: 5%;
    font-size: 0.875rem;
    white-space: nowrap;
}

.business__Wallet__Transactions {
    margin: 4% 0 10%;
    padding-top: 0;
}

.business__Wallet__Transactions table{
    margin-top: 3%;
}

.business__Wallet__Transactions h3 {
    font-weight: 400;
}
