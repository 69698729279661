.layout-container{
    text-align: center;
    padding-top: 50px;
}

.layout-container img.layout-logo{
    width: 60px;
}

.layout-container h1{
    padding-top: 20px;
    font-weight: 300;
}

.layout-container p{
    width: 400px;
    font-size: 1.1rem;
    margin: 0 auto;
    padding-top: 20px;
}

.layout-container h3.layout-title{
    padding-top: 40px;
    font-weight: 400;
    font-size: 2rem;
    color: #354661;
    padding-bottom: 40px;
}

.layout-container .progress{
    padding-top: 50px;
    display: flex;
    width: 250px;
    height: auto;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.layout-container .progress .divider{
background-color: #EDEDED;
width: 60px;
height: 4px;
border-radius: 10px;
}

.layout-container .progress .step{
    width: 30px;
    height: 30px;
    background-color:  #EDEDED;
    text-align: center;
    border-radius: 50%;
}

.layout-container .progress .step span{
position: relative;
top:5px;
}

.layout-container .layout-wrapper{
 width: 80%;
 height: auto;
 border-radius: 20px;
 background: #EDEDED;
 margin: 0 auto;
}

.layout-container button.next-btn{
    margin-top: 30px;
    width: 250px;
    height: 35px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    font-family: inherit;
    border-radius: 10px;
    background-color: #34A853;
    margin-bottom: 20px;
    font-size: 1.1rem;
}
.layout-container button.prev-btn{
    margin-top: 30px;
    width: 250px;
    height: 35px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    font-family: inherit;
    border-radius: 10px;
    background: #BEBEBE;
    margin-bottom: 20px;
    font-size: 1.1rem;
    margin-right: 30px;
}


/*page one componenet styles*/
.business-container{
    padding-top: 40px;
    padding-bottom: 40px;
}
.business-image-upload{
    width: 100px;
  position: relative;
  margin: auto;
}

 .business-image-upload img{
    width: 60px;
    height: 60px;
    border-radius: 60px;
 }
    
 .business-image-upload .round-container i.fa-pencil:hover{
    cursor: pointer;
 }

.business-image-upload .round-container{
   position: absolute;
   top:39px;
   left:53px;
    /* background: white; */
    width: 32px;
    height: 32px;
    cursor: pointer;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    /* overflow: hidden; */
}

.business-image-upload .round-container input[type="file"]{
    position: absolute;
    transform: scale(2); 
    opacity: 0;
    left:-10px;
    top:5px;
    /* border: 2px solid red; */

}
input[type=file]::-webkit-file-upload-button{
    cursor: pointer;
}


.business-container h5{
    padding-top: 20px;
    font-size: 1.3rem;
    font-weight: 300;
}


.business-container input[type="text"]{
    margin-top: 20px;
    padding-top: 10px;
    width: 80%;
    height: 80px;
    padding-left: 20px;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 1rem;
    padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.config-social-input{
    width: 80%;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    padding-left: 20px;
    font-family: inherit;
}

.config-social-input::placeholder{
    color: black;
}

.business-container input[type="text"]::placeholder{
    font-size: 1rem;
    color: black;
}

/*page two componenet styles*/




/**affiliate program*/
.affiliate-program h5{
    width: 350px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 300;
    line-height: 20px;
}

.affiliate-program input{
    width: 50%;
    padding-left: 10px;
    height: 35px;
    border-radius: 10px;
    outline: none;
    border:none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-right: 10px;
    font-family: inherit;
}