.dash_home_container {
    width: 100%;
    height: auto;
    position: relative;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
   
}
.dash_home_container::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.dash_home_container .dash_mid {
    width: 100%;
    height: auto;
}
.dash_mid {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.dash_mid_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dash_mid .dash_mid_top, .dash_mid .dash_mid_base {
    width: 100%;
    height: auto;
    
}
.dash_mid {
    margin-top: 20px;
}
.dash_mid_top > span {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
}
.url {
    width: 310px;
    height: 40px;
    border-radius: 20px;
    background: #F2FFF6;
    margin-right:15px;
}

.url .url_content {
    width: 70%;
    height: auto;
    
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}
.url_content > span {
    font-size: 11px;
    font-weight: bold;
}
.url_img {
    display: flex;
}
.url_img > span {
    width: 15px;
    height: 15px;
}
.copy-icon{
        width: 25px;
        height: 25px;
        color: #0FA958;
        margin-top: -7px;
        margin-left: -20px;
        cursor: pointer;
}
.url_img .img2 {
    margin-top: -3px;
    margin-left: -12px;
}
.dash_mid_base {
    margin-top: 10px;
  
}
.dash_mid_base .dash_mid_overflow {
    width: 100%;
    height: 250px; 
    box-sizing: border-box;
    padding: 0px 20px;
}
.dash_mid_overflow .dash_mids_wrapper {
    width: 31%;
    height: 200px;
    background: #FCFCFC;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}
.dash_mids_wrapper .dash_mids_content {
    width: 90%;
    height: 85%;
 
}
.dash_mids_content .dash_midd_top {
    width: 140px;
    height: 35px;
    font-size: 13px;
    
    display: flex;
    justify-content: center;
    border-radius: 25px;
    background: #B9F0D2;
}
.dash_mid_mid {
    display: flex;
    font-weight: 700;
    font-size: 35px;
    align-items: center;
    margin-top: 25px;
}
.dash_mid_bottom .dash_mid_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #B9F0D2;
}
.dash_mid_img > img {
    width: 50%;
    height: 50%;
}
.dash_mid_bottom > span {
    font-size: 13px;
    cursor: pointer;
}
.dash_mid_bottom {
    margin-top: 15px;
    
}

.dash_base .dash_base_top {
    width: 100%;
}
.dash_base .dash_base_main {
    width: 98%;
    /* height: 650px; */
    margin: 0 auto;
}

.dash_base_top > span {
    color: #354661;
    font-size: 18px;
    margin-left: 20px;
    padding-bottom: 10px;
}
.filter {
    width: 80px;
}
.filter_img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
}
.filter_img > img {
    width: 20px;
    height: 20px;
}
.dash_base_main {
    height: 350px;
    border-radius: 20px;
    margin-top: 10px;
    box-sizing: border-box;
    margin-left: 5px;
    background: #FCFCFC;
    margin-bottom: 5px;
    box-shadow: 3px 3px 2.5rem 0rem rgba(82,63,105,0.1);
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); */

}
@media screen and (max-width: 699px) {
    .dash_base .dash_base_main {
        width: 95%;
        
    }
}
@media screen and (max-width: 1000px){
    .affiliate_wrapper .affiliate_content {
        width: 90%;
    }
    .affiliate_content .dash_mid {
        width: 100%;
    }
    .dash_mid .dash_mid_base {
        width: 100%;
        overflow-x: scroll;
    }
    .dash_mid_base .dash_mid_overflow {       
        width: 950px;
        height: 250px;
        overflow-x: scroll;
        box-sizing: unset;
        
    }    
    
    .dash_mid_overflow .dash_mids_wrapper {
        width: 300px;     
        height: 200px;

    }
}
@media screen and (max-width: 367px) {
    .dash_mid_base .dash_mid_overflow {
        width: 800px;
    }
    .dash_mid_overflow .dash_mids_wrapper {
        width: 250px;  
    }

}

@media screen and (min-width: 501px) and (max-width: 630px) {
    .dash_mid .dash_mid_top {
        width: 90%;
        
    }
    .dash_mid .dash_mid_top {
        margin-top: -30px;
        display: flex;
        flex-direction: column;
        align-content: space-around;
        justify-content: center;
        
    }
    .dash_mid_top > span {
        padding-bottom: 15px;
    }
    .dash_top h3 {
        font-size: 21px;
    }
    
}
@media screen and (max-width: 464px) {
    .dash_mid_top {
        display: flex;
        flex-direction: column;
        align-content: space-around;
        justify-content: flex-start;
    }
}