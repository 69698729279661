/**global registration form styles**/
.form_content{
      position: absolute;
  left: 100px;
}

.form_content form select{
   width:100%;
   height:43px;
    border-radius: 5px;
    font-family: inherit;
      background: #DBD7D7;
      /* border: none; */
}
 .form_content form select.country{
   margin-top: 135px;
 }


  .form_content form select.state-select{
   margin-top: 10px;
  }

    .form_content form select.state-select option{
      z-index: 999;
      background-color: white;
    }
    .form_content form select.country option{
      z-index: 999;
      background-color: white;
    }
  .form_content form .form-bottom{
   /* position: relative; */
   margin-top:-120px;
  }
  /******/