.wallet_container {
    /* width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    scrollbar-width: none;
    -ms-overflow-style: none; */
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding-top: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.wallet_container::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.wallet_container .wallet_wrapper {
    width: 98%;
    height: auto;
}
.wallet_wrapper .wallet_content {
    width: 100%;
    height: auto;
    /* overflow-y: scroll; */
}
.wallet_content, .wallet_mid_content, .wallet_small_txt {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.wallet_content .wallet_mid_container {
    width: 100%;
    margin-top: 20px;
    /* overflow-x: scroll; */
}
.wallet_content .wallet_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.wallet_top h1 {
    font-size: 28px;
}
.wallet_top .add_account, .modal_base .add_account {
    width: 180px;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    background: #34A853;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.wallet_mid {
    width: max(100%, 900px);
    /* overflow-x: scroll; */
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.wallet_mid::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.wallet_mid_wrapper {
    width: 40%;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}
.wallet_mid_img {
    width: 20%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wallet_mid_wrapper .wallet_mid_content {
    width: 100%;
    height: auto;
    background: #FCFCFC;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 20px 10px;
}

.wallet_mid_content .wallet_mid_top {
    width: 140px;
    height: 35px;
    font-size: 13px;
    box-sizing: border-box;
    display: flex;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background: #B9F0D2;
}
.wallet_mid_mid {
    font-weight: 700;
    font-size: 55px;
    margin-top: 15px;
    color: green;
}
.wallet_mid_content .wallet_mid_texts {
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wallet_mid_top,.wallet_mid_txt, .wallet_mid_base, .wallet_mid_texts, .wallet_mid_mid {
    box-sizing: border-box;
    padding: 10px 20px;
}
.wallet_mid_texts .wallet_small_txt {
    width: auto;
    box-sizing: border-box;
}
.wallet_mid_txt {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5rem;
}
.wallet_small_txt span {
    font-size: 18px;
    line-height: 1.5rem;
}
.wallet_mid_texts .image {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.image img {
    width: 80px;
    height: 80px;
}
.wallet_mid_base {
    display: flex;
    border-top: 1px solid #D9D9D9;
    margin-top: 5px;
    padding-top: 15px;
}
.wallet_mid_base span, .wallet_mid_base .span {
    font-size: 20px;
}
.wallet_mid_base .span {
    color: green;
}
.wallet_mid_base img {
    width: 20px;
    height: 20px;
    padding-left: 7px;
}


.modal_wrapper {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: #fff;
}
.modal_wrapper .modal_content {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    padding: 50px;
}
.modal_content h1, .modal_content > span {
    text-align: center;
    line-height: 2.0rem;
}
.modal_content h1 {
    font-size: 25px;
    
}
.modal_content .big_txt {
    font-size: 40px;
    color: green;
    margin-top: 40px;
    font-weight: 800;
}
.modal_content .med_txt {
    font-size: 18px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    
}
.med_txt .span {
    width: 70%;
}
.med_txt .span {
    line-height: 1.5rem;
}
.modal_content .modal_base {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.modal_content .modal_input {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px 0;
    background: #FFFFFF;
    border-radius: 20px;
    font-weight: 600;
    border-width: 0px;
    font-family: inherit;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    color: black;
    border: none;
    font-weight: 560;
    font-family: inherit;

    /* width: 100%;
    height: 60px;
    padding: 20px;
    box-sizing: border-box;
    margin: 12px 0;
    background: #FFFFFF;
    border-radius: 20px;
    font-weight: 600;
    border-width: 5px;
    background: #FFFFFF;
    border-radius: 10px;
    border: .7px solid rgb(198, 192, 192);
    font-weight: 300;
    font-family: inherit; */
}
.modal_input input {
    width: 90%;
    height: 90%;
    border: none;
    font-family: inherit;
    outline: none;
    background: none;
}
input::placeholder{
 color: black;
 font-style: normal;
 
}

@media screen and (max-width: 1200px) {
    .wallet_content .wallet_mid_container {
        width: 100%;
        margin-top: 20px;
        overflow-x: scroll;
    }
    .wallet_mid_wrapper {
        width: 350px;
    }
    .wallet_mid_img {
        width: 5rem;
    }
}
@media screen and (max-width: 800px) {
    .med_txt .span {
        line-height: 1.5rem;
        font-size: 15px;
        width: 95%;
    }
    .modal_content .modal_input {
        width: 95%;
    }
}
@media screen and (max-width: 600px) {
    .modal_wrapper .modal_content {
        padding: 40px 20px;
    }
    .med_txt .span {
        line-height: 1.5rem;
        font-size: 14px;
        width: 95%;
    }
    .modal_content .modal_input {
        width: 99%;
    }
}
@media screen and (min-width: 1400px) {
    .med_txt .span {
        line-height: 1.5rem;
        width: 70%;
    }
}