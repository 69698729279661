.product__Modal__Overlay.bg__Modal {
    width: calc(100% - 78px);
    left: 78px;
    z-index: 100000;
}

.product__Modal__Container .preview__Background__Container .title{
    font-weight: 700;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container,
.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Search__Container,
.product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item .done__Btn {
    display: flex;
    align-items: center;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container{
    margin-top: 2%;
    margin-bottom: 3%;
    width: 96%;
    justify-content: space-between;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item{
    margin-left: 4%;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: auto;   
    width: calc(40% - 4%);
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item p{
    font-size: 1.3rem;
    margin-bottom: 10%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item select{
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 3%;
    cursor: pointer;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item .done__Btn{
    display: block;
    border: none;
    border-radius: 10px;
    padding: 3% 4%;
    cursor: pointer;
    outline: none;
    margin-top: 15%;
    background: #34A853;
    font-size: 1rem;
    color: #fff;
    width: 100%;
}

.product__Modal__Container .preview__Background__Container .default__Container{
    background: #fff;
    padding: 3.2% 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.12);
    width: 60%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container{
    width: 100%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container,
.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item,
.product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item .done__Btn {
    transition: 0.35s ease-in-out;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item{
    width: 70%;
    margin: 0 auto;
    padding-top: 4%;
    font-size: 0.7rem;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container,
.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Search__Container{
    width: 100%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Search__Container{
    background: #fff;
    border-radius: 5px;
    color: #979A93;
    padding: 1.3% 0;
    position: relative;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Search__Container > *:first-child{
    position: absolute;
    left: 3%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Search__Container > *:last-child{
    margin-left: 13%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container > *{
    display: inline-block;
    margin-top: 2.5%;
    margin-bottom: 7.5%;
    width: calc( (100% / 2) - 1% );
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container > *:first-child{
    margin-right: 2%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container .preview__Product__Item img {
    max-width: 100%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container .preview__Product__Item p {
    margin-left: 6%;
    margin-top: 1%;
}

.product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container .preview__Product__Item {
    padding-top: 5%;
}

@media screen and (max-width: 991.98px) {

    .product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item p{
        margin-bottom: 5%;
    }

    .product__Modal__Container .preview__Background__Container .store__Preview__Container{
        flex-direction: column-reverse;
    }

    .product__Modal__Container .preview__Background__Container .store__Preview__Container > *,
    .product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item {
        width: 90%;
    }
    
    .product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container{
        padding-top: 4%;
        padding-bottom: 6%;
    }

    .product__Modal__Container .preview__Background__Container .store__Preview__Container .store__Preview__Item__Container .store__Preview__Item .preview__Products__Container > *{
        display: block;
        width: 100%;
        margin-bottom: 5%;
    }

    .product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item .done__Btn{
        margin-top: 4%;
        margin-bottom: 10%;
    }

    .product__Modal__Container{
        max-height: 80vh;
        overflow: scroll;
    }

    .product__Modal__Container .preview__Background__Container .default__Container{
        padding-top: 5%;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .product__Modal__Container .preview__Background__Container .default__Container{
        margin-top: 3%;
        padding-top: 9%;
    }
}

@media screen and (max-width: 576.98px ) {
    .product__Modal__Container .preview__Background__Container .default__Container{
        margin-top: 5%;
        padding-top: 9%;
    }
    .product__Modal__Container .preview__Background__Container .store__Preview__Container .change__Color__Item .done__Btn{
        margin-top: 8%;
        margin-bottom: 15%;
    }
    .product__Modal__Container{
        left: 35%;
    }
}

@media (min-width: 718px) and (max-width: 768px){
    .product__Modal__Container{
        left: 46%;
    }
}

@media (min-width: 658px) and (max-width: 717.98px){
    .product__Modal__Container{
        left: 44.5%;
    }
}

@media (min-width: 598px) and (max-width: 657.98px){
    .product__Modal__Container{
        left: 40%;
    }
}

@media (min-width: 538px) and (max-width: 597.98px){
    .product__Modal__Container{
        left: 32%;
    }
}

@media (min-width: 478px) and (max-width: 537.98px){
    .product__Modal__Container{
        left: 30%;
    }
}

@media screen and (max-width: 477.98px) {
    .product__Modal__Container{
        left: 23%;
        width: 36%;
    }
}