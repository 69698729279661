#loading {
    width: 1rem;
    height: 1rem;
    border: 2px solid #d3d3d3;
    border-top: 3px solid #38b6ff;
    border-radius: 100%;
    margin: 2% auto;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}