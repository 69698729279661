@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.payout__Modal .info__Text {
    width: 60%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.payout__Modal .amount__Details__Container .current__Available__Balance__Item {
    color: #219653;
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    margin-bottom: 4%;
}

.payout__Modal .account__Details__Container .amount__Details {
    color: #219653;
    margin-left: 7%;
    align-self: flex-start;
}

.payout__Modal .account__Details__Container .amount__Details .amount__Details__Icon {
    margin-top: 2%;
}