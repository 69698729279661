.download-container{
    /* padding:20px; */
}

.download-container .box-1 img{
    width: 50px;
}

.download-container .box-1{
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.download-container .box-1 h4{
    padding-right: 50px;
}

.download-container .download-content{
    padding: 20px;
}

.download-container .download-content img{
    width: 150px;
}

.download-container .download-content button{
    background-color: #34A853;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    width: 150px;
    height: 30px;
    border-radius: 10px;
    font-family: inherit;
    margin-top: 10px;
}

.download-container .download-content p{
font-size: 1.1rem;
}

.download-container .box-2{
    border-top: 1px solid silver;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    padding-top: 10px;
}

.download-container .box-2 img{
    width: 40px;
    margin-right: 10px;
}

.download-container .box-2 span{
position: relative;
top: -15px;
color: #34A853;
}

@media screen  and (max-width:320px){
    .download-container .box-2 span{
        font-size: .7rem;
}
}