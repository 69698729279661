@media only screen and (min-width: 200px) and (max-width: 300px){
.login_content .landing_header{
    font-size: 15px;
    word-spacing: 4px;
}


.login_content .proceed_signup{
    top: 250px;
    font-size: 12px;
}

}

@media only screen and (min-width: 200px) and (max-width: 600px){
    .login_content{
    left:20px;
}

.login_input label{
    left: 7px;
}
}

@media only screen and (min-width: 301px) and (max-width: 600px){
.login_content .proceed_signup{
    top: 250px;
    left:20px;
    font-size: 12px;
}
}

@media only screen and (min-width: 301px) and (max-width: 400px){
    .login_content button{
    width:250px;
}
}

/* @media only screen and (min-width: 401px) and (max-width: 500px){
        .login_content button{
    width:300px;
}
} */

@media only screen and (min-width: 501px) and (max-width: 600px){
   .login_content{
    left:15%;
}

           .login_content button{
    width:350px;
}
}

@media only screen and (min-width: 601px) and (max-width: 800px){
    .login_content button{
        width:70vw;
    }
    }

    @media only screen and (min-width: 801px) and (max-width: 1100px){
        .login_content button{
            width:350px;
        }
    }

@media only screen and (min-width: 801px) and (max-width: 1020px){
       .login_content{
    left:20px;
}

 

.login_input label{
    left: 7px;
}

.login_content .proceed_signup{
    top: 250px;
    left:20px;
    font-size: 12px;
}
}
    
@media only screen and (min-width: 301px) and (max-width: 500px) {
    .login_content button{
        width: 87vw;
    }
}
    