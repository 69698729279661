*{
    margin:0;
    padding: 0;
}

a{
    list-style-type: none;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

li{
    cursor: pointer;
}
.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75px;
    background-color: rgba(249, 248, 248, 1);
}

 .logo{

}

.navbar .nav-links{
    display: flex;  
    justify-content: flex-end;
    list-style: none;
    width: 75%;
}

.navbar .nav-links .busin,
.navbar .nav-links  .indiv,
 .navbar .nav-links .resources,
.navbar .nav-links  .login,
 .navbar .nav-links .start{  
     color: black;
     font-size: 15px;
     padding: 10px 10px;
     margin: 0 10px;
     position: relative;
     right: -100px;
     cursor: pointer;
     font-weight: 600;
 }

 .navbar .nav-links .start a{
     padding: 10px 30px 10px 30px;
     border-radius: 20px;
     background-color: rgba(52, 168, 83, 1);
     color: white;
 }

  /* .navbar .nav-links li:hover, .navbar .nav-links li a:hover{
      font-weight: 400;
      cursor: pointer;
  } */

  .navbar .mobile-menu-icon{
      display: none;
  }

  @media screen  and (max-width:982px){

      .navbar .nav-links .logo{
          display: flex;
          position: absolute;
          top:15px;
          left: 35px;
      }

      .navbar .nav-links{
display: none; 
      }

      body::-webkit-scrollbar {
        /* display: none;  */
        
    }

       .navbar .nav-links-mobile{ 
          position: absolute;
          list-style:none;
          background-color: white;
          left:0;
          top:65px;
          height: 100vh;
          /* overflow: hidden; */
          z-index: 999;
          cursor: pointer;
          transition: all 0.5s ease-out;
          width:100vw;
            display: block;
      }

      /* .navbar .busin,
.navbar .indiv,
 .navbar .resources,
.navbar .login,
 .navbar .start{
     color: black;
     text-align: center;
     padding: 32px;
     width: 100%;
     font-weight: 500;
     text-decoration: none;
     transition: all 0.5s ease;
 } */

 .navbar li{
		  color: black;
     text-align: center;
     padding: 20px 8px;
     width: 100%;
     font-weight: 500;
     text-decoration: none;
     transition: all 0.5s ease;
	   list-style-type:none;
	}

 .navbar li:hover{
  font-weight: 400;
  transition: all 0.15s ease-in-out;
 }

  .navbar .mobile-menu-icon{
      display: block;
      position: absolute;
      font-size: 30px;
      color: black;
      outline: none;
      border: none;
      background: transparent;
      top:15px;
      right: 25px;
      /* left: 25px; */
  }

 .navbar .nav-links-mobile .start a{
     padding: 10px 30px 10px 30px;
     border-radius: 20px;
     background-color: rgba(52, 168, 83, 1);
     color: white;
 }

/* .navbar .nav-links-mobile li:hover, .navbar .nav-links li a:hover{
      font-weight: 400;
      cursor: pointer;
  } */

  }
