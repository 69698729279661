.sales_container {
    width: 100%;
    /* height: 90%; */
    position: relative; 
}
.sales_container, .emptysales_container, .button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.emptysales_container {
    width: max(300px, 40%);
    height: 100%;
    
}
.emptysales_container .emptysales_wrapper {
    width: 90%;
    height: 95%;
}
.emptysales_wrapper {
    overflow-y: scroll; 
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.emptysales_wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.emptysales_wrapper .emptysales_content {
    width: 100%;
    height: auto;
}

.emptysales_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-around;
    align-items: center;
}
.emptysales_content >img {
    width: 150px;
    height: 150px;
}
.emptysales_content h2 {
    font-size: 18px;
    margin-top: 15px;
}
.emptysales_content > span {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}
.button {
    width: 200px;
    height: 45px;
    margin-top: 30px;
    background: #34A853;
    border-radius: 15px;
}
.button .button_content {
    width: 70%;
    height: auto;
}
.button_content {
    display: flex;
    justify-content: space-between;
}
.button_content span {
    font-size: 15px;
    color: white;
}
.button_content img {
    width: 15px;
    height: 10px;
    padding-top: 3px;
}

/***brands section stylings*/
div.brands-first-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    margin-left: 20px;
    /* align-items: center; */
}

div.brands-first-section h3{
    color: #283A57;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
}

div.brands-first-section form{
    /* width: 100%; */
}

div.brands-first-section input{
margin-right: 33px;
width: 40%;
height: 50px;
outline: none;
border: none;
/* box-shadow: 0 2px 5px 1px rgb(64 60 67 / 10%); */
box-shadow:  0 2px 5px 1px rgb(64 60 67 / 18%);
padding-left: 10px;
font-family: inherit;
border-radius: 20px;
}

div.brands-first-section input::placeholder{
    color: black;
}

div.brands-second-section{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 0;
    height: auto;
    padding-bottom: 20px;
    margin-left: 5px;
}

div.brands-second-section div.brands-content{
    width: 30%; 
   min-height: 300px;
    margin: 15px;
    box-sizing: border-box;
    float: left;
    text-align: center;
    border-radius:30px;
    box-shadow:  0 2px 5px 1px rgb(64 60 67 / 18%);
    transition: .4s;
    background-color: white;
    margin-bottom: 10px;
    }

    div.brands-content img{
        width: 70px;
        height: 70px;
        border-radius: 70px;
        float: left;
        /* background-color: silver; */
        /* border: 2px solid black; */
        margin-left: 20px;
        margin-top: 20px;
    }

    div.brands-content .brands-details{
        padding-top: 30px;
        height: auto;
        /* text-align: left; */
    }

    div.brands-content .brands-details h4{
padding-bottom: 40px;
padding-right: 80px;
font-weight: 300;
font-size: 1.5rem
    }

    div.brands-content .brands-details p{
      text-align: left;
        margin-top: 4px;
        padding-left: 30px;
        margin-bottom: 10px;
        /* padding-bottom: 30px; */
    }

    div.brands-content .brands-details span{
  position: relative;
 top:15px;
 left: 10px;
 float: left;
  /* right:60px; */
        }

        div.group-comission{
            /* margin-bottom: 20px; */
        }

    div.brands-content .brands-details .store-url{
        margin-top: 50px;
        width: 85%;
        height: 60px;
        border-radius: 10px;
        background-color: #F2FFF6;
        /* margin-left: 30px; */
        margin-left: 7%;
        margin-right: 1%;
    }

    div.brands-content .brands-details .store-url a{
      position: relative;
      right: 0px;
      font-size: 10px   ;
      top:-6px;
      padding-bottom: 10px;
    }

    div.brands-content .brands-details .store-url .copy-img{
        color: #0FA958;
        padding-top: 9px;
        padding-right: 5px;
        cursor: pointer;
        width: 25px;
        height: 25px;
    }

    /* @media(max-width: 1000px){
        div.brands-second-section div.brands-content{
        width: 65%;
        }
    }*/

    @media(max-width: 500px){
        div.brands-second-section div.brands-content{
            width: 100%;
            }
        div.brands-first-section{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        div.brands-first-section input{
            width: 80%;
            margin-right: 2%;
        }

        div.brands-first-section h2{
            margin-bottom: 10px;
        }
    }

    @media(max-width: 1051px){
        div.brands-second-section{
justify-content: center;
        }

        div.brands-second-section div.brands-content{
            width: 80%;
            /* min-height: 400px; */
            }
    }

    