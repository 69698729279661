.settings_config_container {
    position: relative;
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.settings_config_container h3 {
    width: 100%;
    font-size: 23px;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    color: #354661;

}
.settings_config_container .settings_config_wrapper {
    margin-top: 30px;
    width: 90%;
    height: auto;
    box-sizing: border-box;
    padding-bottom: 65px;
    background: #FFFFFF;
    border-radius: 30px;
}
.settings_config_wrapper, .settings_config_header {
    display: flex;
    justify-content: center;
    align-items: center;
}
.settings_config_wrapper .settings_config_content {
    width: 60%;
    height: 90%;
    box-sizing: border-box;
    
    padding-top: 40px;
}
.settings_config_content .settings_config_header {
    width: 100%;
    height: auto;
}
.settings_config_header span {
    text-align: center;
    color: #000000;
    font-weight: 600;
}
.settings_config_content .settings_config_body {
    width: 97%;
    height: auto;
    box-sizing: border-box;
}
.settings_config_content, .settings_config_body, .img_logo {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.settings_config_content .settings_config_base {
    width: 90%;
    height: auto;
    box-sizing: border-box;
}
.settings_config_base {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-left: 20px;
    
}
.settings_config_base button {
    width: 200px;
    height: 60px;
    margin: 0 auto;
    color: white;
    background: #34A853;
    font-size: 17px;
    display: flex;
    border: none;
    outline: none;
    font-family: 'Comfortaa';
    cursor: pointer;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}
.settings_config_base .socials {
    width: auto;
    display: flex;
    padding-left: 10px;
    box-sizing: border-box;
}

.socials img {
    width: 25px;
    height: 25px;
}
.socials span {
    padding-left: 15px;
    font-size: 18px;
    padding-top: 4px;
    font-weight: 600;
}
.settings_config_body .settings_config_input {
    position: relative;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    margin-top: 50px;
}
.settings_config_input input {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    outline: none;
    border: none;
    /* font-family: 'Comfortaa'; */
    font-weight: 600;
    border-width: 0px;
    font-family: inherit;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.input-upload {
    display: none;
    visibility: none;
}

.settings_config_input input::placeholder {
    font-weight: 600;
    font-size: 17px;
    font-family: 'Comfortaa';
    color: #000000;
}
@media screen and (max-width: 999px) {
    .settings_config_wrapper .settings_config_content {
        width: 80%;
        /* overflow-x: auto; */
    }
    
    .settings_config_content .settings_config_base {
        width: 99%;
        height: auto;
    }
    .settings_config_base {
        margin-left: 3px;
    }
    .socials {
        padding-left: 5px;
       
    }
    .socials img {
        width: 20px;
        height: 20px;
    }
    .socials span {
        padding-left: 10px;
        font-size: 15px;
        padding-top: 4px;
    }
}
/* @media screen and (max-width: 769px) {
    .settings_config_wrapper .settings_config_content {
        width: 50%;
    }
} */
@media screen and (max-width: 540px) {
    .settings_config_wrapper .settings_config_content {
        width: 85%;
    }
}
/* @media screen and (max-width: 670px) {
    .settings_config_wrapper .settings_config_content {
        width: 88%;
    }
} */
@media screen and (max-width: 769px) {
    .settings_config_wrapper .settings_config_content {
        width: 85%;
    }
    .settings_config_content .settings_config_base {
        width: 99%;
        height: auto;
        /* box-sizing: border-box; */
    }
    .socials {
        padding-left: 5px;
        /* box-sizing: border-box; */
    }
    .socials span {
        padding-left: 8px;
        font-size: 15px;
        padding-top: 4px;
    }
}
@media screen and (max-width: 570px) {
    .socials span {
        padding-left: 8px;
        font-size: 13px;
        padding-top: 4px;
    }
}
@media screen and (max-width: 399px) {
    .settings_config_content .settings_config_base {
        width: 100%;
        height: auto;
        /* overflow-x: scroll; */
    }
    .settings_config_base {
        margin-left: -12px;
        box-sizing: border-box;
    }
    .socials {
        padding-left: 1px;
        box-sizing: border-box;
    }
    .socials span {
        font-size: 11px;
        padding-left: 3px;
        font-weight: 700;
        padding-top: 1px;
    }
    .socials img {
        width: 13px;
        height: 13px;
    }
}