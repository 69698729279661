@media only screen and (max-width: 400px){
    .box_2{
        padding-bottom: 20px;
    }

    .box_7{
     padding-bottom: 30px;
    }

    .box_4_section_1{
 margin-left: 20px;
    }

    .home-banner .home-bannner-text h1{
        font-size: 1.7rem;
        line-height: 25px;
        width: 250px;
        letter-spacing: .1px;
    }

    .home-banner .home-bannner-text p{
        width: 250px;
        font-weight: 800;
        margin-top:80px;
    }

    .home-banner-register input{
        width: 290px;
    }

    .home-banner-register button{
        position:  absolute;
        left: 0px;
        top:50px;
        width: 215px;
        }

        .box_4_section_1 img{
            opacity: .6;
        }

        .box_3 .box_3_section_2 img{
            opacity: .3;
            width: 300px;
        }
    
        .box_3 .box_3_section_2 {
            right: 10px;
            top:850px;
        }

        .box_3 .box_3_section_1 h1{
            font-weight: 1000;
          }
      
          .box_3 .box_3_section_1 p{
              font-weight: 700;
          }
      
}

@media only screen and (min-width: 400px) and (max-width: 588px){
    .box_3 .box_3_section_2 img{
        opacity: .3;
        width: 350px;
    }

    .box_3 .box_3_section_2 {
        right: 10px;
        top: 830px;
    }

    .box_4_section_1 img{
        opacity: .6;
    }

    .home-banner .home-bannner-text p{
        font-weight: 1000;
        font-size: .7rem;
        margin-top: 83px;
    }

    .home-banner .home-bannner-text h1{
        font-size: 3rem;
        width: 400px;
        line-height: 49px;
    }


    .box_3 .box_3_section_1 h1{
      font-weight: 800;
    }

    .box_3 .box_3_section_1 p{
        font-weight: 500;
    }


  
}


@media only screen and (max-width: 800px){
    .home-banner .home-banner-img img{
        opacity: .5;
    }

    .home-banner-register input{
        width: 200px;
    }

    .box_3 h1, .box_4 h1{
      font-size: 1.6rem;
    }

    .box_3 p, .box_4 p{
        font-size: 1rem;
        width: 150px;
    }

    .box_3 button.start_selling, .box_4 button.start_earning{
        width: 20vw;
        border-radius: 7px;
        font-size: 1rem;
        height: 33px;
        min-width: 10rem;}

    .box_3 .box_3_section_2 img{
        opacity: .3;
        width: 350px;
    }

    .box_3 .box_3_section_2 {
        right: 10px;
        top: 830px;
    }
}   

@media only screen and (max-width: 1000px){

.home-banner .home-banner-img img, .box_3_section_2{
    right: 10px;
}
    
    .box_2 p{
        width: 100%; 
    }

    .box_7 h1{
        font-size: 2.2rem;
        min-width: 22rem;
    }

    .box_7 button{
       font-size: .9rem;
       height: 40px;
       border-radius: 5px;
       min-width: 10rem;
    }
}


/* .home-banner .home-bannner-text h1{
    position: relative;
    top: 80px;
    font-size: 50px;
    font-weight: lighter;
    width: 400px;
    left:30px;
    line-height: 53px;
    color: #283A57;
    } */