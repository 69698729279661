
@media only screen and (min-width: 200px) and (max-width: 300px) {
    .site_input input{
        width: 100%;
    }

    .form_content{
       /* transform: translateX(-80px); */
       transform: translateX(-20px);
    }
}


@media only screen and (min-width: 301px) and (max-width: 360px) {
    .form_content{
 transform: translateX(-84px);
    }

      .site_input input{
       width: 90vw;
    }
}

@media only screen and (min-width: 361px) and (max-width: 400px) {
   .form_content{
 transform: translateX(-81px);
    }
     .site_input input{
       width: 90vw;
    }
}

@media only screen and (min-width: 401px) and (max-width: 500px){
   .form_content{
       transform: translateX(-79px);
    }

     .site_input input{
       width: 90vw;
    }
}

@media only screen and (min-width: 501px) and (max-width: 600px){
.form_content{
       transform: translateX(-75px);
    }
}

@media only screen and (min-width: 601px) and (max-width: 800px){
/* 
  .form_content{
       transform: translateX(-43px);
    } */
     .site_input input{
       width: 70vw;
    }
}
@media only screen and (min-width: 801px) and (max-width: 1100px){
 .form_content{
       transform: translateX(-60px);
    }

    .site_input input{
        width: 350px;
    }

    .form_content form select.country, .form_content form select.state-select{
  width: 95%;
 }

}
