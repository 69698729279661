.add__Account__Modal {
    width: 40%;
    padding-left: 1%;
    padding-right: 1%;
    text-align: center;
    box-sizing: border-box;
}

.add__Account__Modal .close__Icon{
    margin-right: 0;
}

.add__Account__Modal .info__Text {
    margin: 4% 0 6%;
}

.add__Account__Modal .account__Details__Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.add__Account__Modal .account__Details__Container input, 
.add__Account__Modal .account__Details__Container select,
.add__Account__Modal .account__Details__Container button {
    display: block;
    font-family: 'Comfortaa', cursive;
    margin: 1% 0;
    outline: none;
    border: none;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 2.5%;
}

.add__Account__Modal .account__Details__Container input[name=accountNumber]:hover, 
.add__Account__Modal .account__Details__Container input[name=accountNumber]:focus{
    box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px 1px;
    border: medium none;
    outline: currentcolor none medium;
}

.add__Account__Modal .account__Details__Container input{
    width: 80%;
}

.add__Account__Modal .account__Details__Container select{
    width: 85%;
    cursor: pointer;
}

.add__Account__Modal .account__Details__Container button {
    width: max-content;
    margin-top: 4%;
    background-color: #34A853;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: 0.35s ease-in-out;
    padding-left: 7%;
    padding-right: 7%;
}

.add__Account__Modal .account__Details__Container button:disabled,
.add__Account__Modal .account__Details__Container button[disabled]{
    cursor: not-allowed;
    opacity: 0.8;
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .add__Account__Modal .title {
        margin-top: 4%;
    }
    .add__Account__Modal .account__Details__Container button {
        margin-top: 8%;
        margin-bottom: 4%;
    }
    .add__Account__Modal .account__Details__Container input, 
    .add__Account__Modal .account__Details__Container select,
    .add__Account__Modal .account__Details__Container button {
        padding-top: 3%;
        padding-bottom: 3%;
    }
}

@media screen and (max-width: 500px) {
    .add__Account__Modal .title {
        margin-top: 7%;
    }
    .add__Account__Modal .info__Text{
        margin: 6% 0 10%;
    }
    .add__Account__Modal .account__Details__Container input, 
    .add__Account__Modal .account__Details__Container select,
    .add__Account__Modal .account__Details__Container button {
        margin: 2% 0;
        padding: 4%;
    }
    .add__Account__Modal .account__Details__Container select{
        width: 89%;
    }
    .add__Account__Modal .account__Details__Container button {
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 10%;
        margin-bottom: 7%;
    }
}