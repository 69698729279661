.ver-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
}
.ver-container .ver-wrapper {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;

}
.ver-wrapper .ver-brand {
    width: 100%;
    
}
.ver-wrapper .note-wrapper {
    width: 95%;
}
.ver-brand {  
    height: 100px;
    box-sizing: border-box;
    padding-top: 50px;
}
.ver-wrapper .note-wrapper {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.note-wrapper .note-content {
    width: 600px;
    height: 100%;
    margin-top: 30px;
    padding-top: 30px;
    display: flex;
    border-radius: 10px;
    background: #F5F6F5;
    flex-direction: column;
    align-content: space-around;
}

.note-wrapper, .ver-brand, .icon, .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-content {
    width: 100px;
    height: auto;
    border-radius: 50%;
    background: rgba(52, 168, 83, 0.1);
}
.icon-content > img {
    width: 50px;
    height: 80px;
    padding-bottom: 8px;
}
.note-content > span {
    
    text-align: center;
}
.ver-big-text {
    font-size: 23px;
    margin-top: 30px;
    margin-bottom: 25px;
    line-height: 1.5rem;
    font-weight: 700;
    color: #354661;
}

.ver-med-text {
    font-size: 18px;
    margin-top: 25px;
    line-height: 0.5rem;
    color: #354661;
    font-weight: 450;
}
@media screen and (max-width: 450px) {
    .ver-container .ver-wrapper {
        /* width: 400px; */
        height: 600px;
    }
    .ver-wrapper .note-wrapper {
        height: 500px;
    }
}
@media screen and (min-width: 451px) and (max-width: 550px) {
    .ver-container .ver-wrapper {
        /* width: 480px; */
        height: 600px;
    }
}
@media screen and (max-width: 560px) {
    .ver-med-text {
        padding: 0px 10px;
        font-size: 14px;
    }
}
@media screen and (max-width: 379px) {
    .ver-med-text {
        padding: 0px 5px;
        font-size: 12px;
        line-height: 0.1rem;
    }
  
}