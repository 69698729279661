    .settings_container {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 5px;
    padding-top: 30px;
    /* overflow-x: scroll; */
    scrollbar-width: none;
    overflow-x: auto;
    -ms-overflow-style: none;

    /* overflow: hidden; */
    /* background-color: red; */
}

.settings_container::-webkit-scrollbar {
width: 0;
height: 0;
}
.settings_container {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}

.settings_container h3 {
    font-size: 23px;
    font-weight: bold;
}
.settings_container .settings_wrapper {
    width: 100%;

}
.settings_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.settings_wrapper {
    height: auto;
    padding-bottom: 20px;
    margin-top: 20px;
    border-radius: 20px;
    background: #C4C4C44D;
}
.settings_wrapper .settings_content {
    width: 95%;
    height: auto;
    box-sizing: border-box;
}
.settings_navbar_container {
    width: 100%;
    margin-top: 10px;
    position: relative;
    height: 50px;
    display: flex;
    /* overflow-x: auto; */
    justify-content: center;
}
.settings_navbar_container .settings_lists {
    width: 98%;
    display: flex;
    height: 100%;
    list-style: none;
    margin-left: 10px;
    padding-right: 20px;
    box-sizing: border-box;
    overflow-x: scroll;
    
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-bottom: 1px solid #BABABA;
   
}
.settings_lists::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.settings_lists .settings_list {
    width: auto;
    height: 99%;
}
.settings_list {
    box-sizing: border-box;
    margin: 0px 25px;
}
.settings_list:nth-child(1) {
    margin-left: 0px;
}
.settings_list:nth-child(4) {
    margin-right: 10px;
}
.settings_list .span {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
}
@media screen and (max-width: 900px) {
    /* .settings_navbar_container {
        overflow-x: auto;
    } */
    .settings_navbar_container .settings_lists {
        width: 97%;
        overflow-x: auto;
    }
    .settings_list {
        box-sizing: border-box;
        margin: 0px 25px;
    }
    .settings_list .span {
        font-size: 18px;
        font-weight: 600;
        /* white-space: nowrap; */
    }
}
@media screen and (max-width: 699px) {
    .settings_navbar_container .settings_lists {
        width: 95%;
        overflow-x: scroll;
        margin: 0px 20px;
    }
    .settings_list .span {
        font-size: 18px;
        font-weight: 600;
        white-space: nowrap;
    }
}
@media screen and (max-width: 500px) {
    .settings_wrapper {
        margin-bottom: 70px;
    }
}

input.social-input::placeholder{
color: black;
font-weight: 300;
}

input.social-input{
    outline: none;
    border: none;
    font-family: inherit;
    height: 40px;
    border-radius: 10px;
    border: 1px solid black;
    padding-left: 10px;
}

select.banks-select{
    width: 100.4%;
    height: 50px;
    font-family: inherit;
    letter-spacing: .1  rem;
    margin-top:50px;
    border-radius: 10px;
    border: 1px solid black;
}

 option{
    letter-spacing: .2rem;
}