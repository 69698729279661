.affiliate_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    overflow-x: hidden;
    justify-content: space-between;
    /* overflow-y: scroll; */
    
    align-items: center;
}
.affiliate_container .affiliate_wrapper_full {
    width: calc(99% - 230px);
    margin-left: 38px;
}

.affiliate_container .affiliate_wrapper {
    width: calc(99% - 120px);
    margin-left: 10px;
}
.affiliate_container .affiliate_wrapper_full,
 .affiliate_container .affiliate_wrapper {
    height: 100%;
    transition: width 1s ease-in-out;
}

.affiliate_wrapper, .affiliate_wrapper_full,
 .tots_img, .url, .dash_mids_wrapper, .dash_midd_top, .dash_mid_img, .filter_img {
    display: flex;
    justify-content: center;
    align-items: center;
}
.affiliate_wrapper .affiliate_content,
.affiliate_wrapper_full .affiliate_content {
    width: 98%;
    height: 92%;
}
.affiliate_content .dash_mid, .affiliate_content .dash_base {
    width: 100%;
    height: auto;
}
.affiliate_content, .dash_mid, .dash_mids_content, .dash_base {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.affiliate_content .dash_top {
    width: 100%;
    padding-bottom: 10px;
    
}


/** profile drop down**/
.affiliate_content .dash_top .profile__Dropdown__Container{
    position: fixed;
    right: 6%;
    top: 80px;
    padding: 0% 1.5%;
    background-color: #fff;
    box-shadow: 5px 5px 2.5rem 0rem rgba(82,63,105,0.1);
    z-index: 1000;
    border-radius: 20px;
    width: max-content;
    box-sizing: border-box;
    width: 13rem;
}

.affiliate_content .dash_top .profile__Dropdown__Container ul li {
    margin: 18% 0;
    display: block;
}

.affiliate_content .dash_top .profile__Dropdown__Container .profile__Dropdown__Icon {
    margin-right: 8%;
    font-size: 1rem;
    font-weight: 400;
    color: #545454;
}

.affiliate_container .dash_top .profile__Dropdown__Container ul li  {
    transition: 0.3s ease-in-out;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.affiliate_content .dash_top .profile__Dropdown__Container ul li:hover,
.affiliate_content .dash_top .profile__Dropdown__Container ul li:focus,
.affiliate_content .dash_top .profile__Dropdown__Container ul li:hover > *,
.affiliate_content .dash_top .profile__Dropdown__Container ul li:focus > * {
    color: #0FA958;
}
/***/

.dash_top, .dash_top .right_top, .dash_mid_top, .dash_mid_overflow, .dash_mid_bottom, .dash_base_top, .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dash_top > h3 {
    justify-content: flex-start;
    font-size: 25px;
}
.dash_top .right_top {
    width: 150px;
}

.right_top .img {
    width: 60px;
    height: 65px;
    border-radius: 40%;
    background-color: #FCFCFC;
    transform: translate3d(3px,2px);
    box-shadow: 0px 4px 3px 0rem rgba(82,63,105,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
    cursor: pointer;
    box-sizing: border-box;
}
.right_top img {
    width: 70px;
    height: 70px;
}

.affiliate_wrapper .notifications_list,
.affiliate_wrapper_full .notifications_list {
    width: 350px;
    position: absolute;
    height: 23rem;
    top: 130px;
    right: 10%;
    z-index: 10000000000;
    background-color: #FCFCFC;
    border-radius: 20px;
    display: flex;
    overflow-x: hidden;
    
    flex-direction: column;
    align-content: space-around;
    box-shadow: 5px 5px 2.5rem 0rem rgba(82,63,105,0.1);
    /* box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.2), -4px -3px 5px 0 rgba(0, 0, 0, 0.2); */
}
.notifications_list > span {
    width: 100%;
    background-color: #FCFCFC;
    text-align: center;
    border-top: .9px solid rgba(217, 203, 203, .9);
    height: 50px;
    box-shadow: 0px -1px .5rem 0rem rgba(82,63,105,0.25);
    box-sizing: border-box;
    padding: 20px 0px;
}
.notifications_list ul {
    width: 100%;
    height: 20rem;
    
    list-style: none;
    
    box-sizing: border-box;
    padding: 10px 0px;
    overflow-y: scroll;
    list-style: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.notifications_list ul::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.notifications_list ul li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px;
    border-bottom: .8px solid rgba(217, 203, 203, .9);
    /* background-color: blue; */
}
.notifications_list ul li:hover{
    background-color: #e8e6e64d;
}
.notifications_list ul li img {
    width: 18%;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
}
.notifications_list ul li .spans {
    width: 80%;
    box-sizing: border-box;
    padding-left: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.notifications_list ul li .spans .txt {
    width: 100%;
    height: auto;
    font-size: 14px;
    color: rgb(52, 47, 47);
    font-weight: 600;
}
.notifications_list ul li .spans .date {
    width: 100%;
    font-size: 12px;
    padding-top: 5px;
    color: #a0aec0;
}
.dash_mid {
    margin-top: 40px;
}
.dash_mid .dash_mid_top, .dash_mid .dash_mid_base {
    width: 100%;
    height: auto;
    
}
.dash_mid_top > span {
    font-size: 20px;
    font-weight: bold;
}
.dash_mid_bottom > span{
    cursor: pointer;
}
.dash_mid_bottom > span:hover,
.dash_mid_bottom > span:focus {
    color: #B9F0D2;
}
.url {
    width: 310px;
    height: 40px;
    border-radius: 20px;
    background: #F2FFF6;
}




@media screen and (max-width: 1000px){
    .affiliate_wrapper .affiliate_content,
    .affiliate_wrapper_full .affiliate_content {
        width: 98%;
    }
    .affiliate_content .dash_mid {
        width: 100%;
    }
    .dash_mid .dash_mid_base {
        width: 100%;
        overflow-x: scroll;
    }
    .dash_mid_base .dash_mid_overflow {       
        width: 950px;
        height: 250px;
        overflow-x: scroll;
        box-sizing: unset;
        
    }    
    
    .dash_mid_overflow .dash_mids_wrapper {
        width: 300px;     
        height: 200px;

    }
}
@media screen and (min-width: 501px) and (max-width: 630px) {
    .dash_mid .dash_mid_top {
        width: 90%;
        
    }
    .dash_mid .dash_mid_top {
        margin-top: -30px;
        display: flex;
        flex-direction: column;
        align-content: space-around;
        justify-content: center;
        
    }
    .dash_mid_top > span {
        padding-bottom: 15px;
    }
    .dash_top h3 {
        font-size: 21px;
    }
    
}
@media screen and (max-width: 500px) {
    .affiliate_container .affiliate_wrapper {
        width: 99%;
        margin-left: 0px;
    } 
}
@media screen and (max-width: 464px) {
    .dash_mid_top {
        display: flex;
        flex-direction: column;
        align-content: space-around;
        justify-content: flex-start;
    }
}
@media screen and (max-width: 430px) {
    .affiliate_container {
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        align-items: center;
    }
    .affiliate_container .affiliate_wrapper {
        height: 100%;
        width: 100%;
        overflow-x: auto;
        box-sizing: border-box;
        transition: width 1s ease-in-out; 
    }
    .affiliate_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .affiliate_wrapper .affiliate_content {
        width: 98%;
        height: 90%;
        
    }
    .dash_top h3 {
        font-size: 20px;
    }
}
.right_top{
    margin-right: -10px;
}

.right_top i.fa-user-circle-o{
    color:  #E0DBDB;
    background-color: #0FA958;
    border-radius: 50%;
    /* margin-right: 40px; */
    cursor: pointer;
    border: 1px solid green;
}

.affiliate_wrapper .affiliate_notifications_list,
.affiliate_wrapper_full .affiliate_notifications_list {
    width: 350px;
    position: absolute;
    height: 23rem;
    top: 65px;
    right: 12%;
    z-index: 10000000000;
    background-color: #FCFCFC;
    border-radius: 20px;
    display: flex;
    overflow-x: hidden;
    
    flex-direction: column;
    align-content: space-around;
    box-shadow: 5px 5px 2.5rem 0rem rgba(82,63,105,0.1);
    /* box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.2), -4px -3px 5px 0 rgba(0, 0, 0, 0.2); */
}
.affiliate_notifications_list > span {
    width: 100%;
    background-color: #FCFCFC;
    text-align: center;
    border-top: .9px solid rgba(217, 203, 203, .9);
    height: 50px;
    box-shadow: 0px -1px .5rem 0rem rgba(82,63,105,0.25);
    box-sizing: border-box;
    padding: 20px 0px;
}
.affiliate_notifications_list ul {
    width: 100%;
    height: 20rem;
    
    list-style: none;
    
    box-sizing: border-box;
    padding: 10px 0px;
    overflow-y: scroll;
    list-style: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.affiliate_notifications_list ul::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.affiliate_notifications_list ul li {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px;
    border-bottom: .8px solid rgba(217, 203, 203, .9);
    /* background-color: blue; */
}
.affiliate_notifications_list ul li:hover{
    background-color: #e8e6e64d;
}
.affiliate_notifications_list ul li img {
    width: 18%;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
}
.affiliate_notifications_list ul li .spans {
    width: 80%;
    box-sizing: border-box;
    padding-left: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.affiliate_notifications_list ul li .spans .txt {
    width: 100%;
    height: auto;
    font-size: 14px;
    color: rgb(52, 47, 47);
    font-weight: 600;
}
.affiliate_notifications_list ul li .spans .date {
    width: 100%;
    font-size: 12px;
    padding-top: 5px;
    color: #a0aec0;
}