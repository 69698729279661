.product__Modal__Overlay {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(30px);
    height: 100%;
    width: 100vmax;
    position: fixed;
    top: 0;
    left: 0;
    animation: fadeIn 0.2s ease-in-out;
    z-index: 20;
}

.product__Modal__Container {
    background-color: #fff;
    width: calc(80% - 3%);
    min-height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #E0DBDB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 1% 0.5% 1% 3%;
    font-family: 'Comfortaa', cursive;
    animation: slideFromBottom 0.35s ease-in-out;
    text-align: left;
}

.product__Modal__Container .close__Icon {
    display: block;
    margin-left: auto;
    margin-right: 0.8%;
    font-size: 2rem;
    cursor: pointer;
}

.product__Modal__Container .product__Detail__Item .product__Price__Container,
.product__Modal__Container .product__Detail__Item {
    display: flex;
}

.product__Modal__Container .product__Detail__Item {
    align-items: flex-start;
    justify-content: center;
    margin-top: 2.5%;
    margin-bottom: 7%;
    height: 80%;
}

.product__Modal__Container .product__Detail__Item .product__Image__Container {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 45%;
    height: 80%;
    padding: 3% 0.8%;
    margin-right: 6%;
}

.product__Modal__Container .product__Detail__Item .product__Image__Container img {
    max-width: 100%;
    width: 100%;
}

.product__Modal__Container .product__Detail__Item .product__Title,
.product__Modal__Container .product__Detail__Item .product__Price__Container .product__Price {
    font-style: normal;
    line-height: 50px;
}

.product__Modal__Container .product__Detail__Item .product__Title {
    font-size: 20px;
    font-weight: 400;
}

.product__Modal__Container .product__Detail__Item .product__Details__Container {
    padding-right: 3%;
    width: 50%;
}

.product__Modal__Container .product__Detail__Item hr {
    border: none;
    border-bottom: 1px solid #BEBEBE;
}

.product__Modal__Container .product__Detail__Item .product__Price__Container {
    justify-content: space-between;
    align-items: center;
    line-height: 50px;
    margin: 3% 0;
}

.product__Modal__Container .product__Detail__Item .product__Price__Container .product__Price {
    font-size: 30px;
    font-weight: 700;
}

.product__Modal__Container .product__Detail__Item .product__Details__Container .cart__Btn .cart__Icon_ {
    font-size: 18px;
    margin-right: 4%;
}

.product__Modal__Container .product__Detail__Item .product__Details__Container .cart__Btn {
    background: #34A853;
    border-radius: 10px;
    color: #fff;
    outline: none;
    border: none;
    font-family: 'Comfortaa', cursive;
    padding: 2% 4%;
    width: 100%;
    margin-top: 20px;
    /* margin: 6% 0; */
    cursor: pointer;
    transition: 0.35s ease-in-out;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__Modal__Container .product__Detail__Item .product__Details__Container .share__Btn {
    background: #34A853;
    border-radius: 10px;
    color: #fff;
    outline: none;
    border: none;
    font-family: inherit;
    padding: 2% 4%;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.35s ease-in-out;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product__Quantity__Container {
    background: #FEFFFE;
    border: 1px solid #979A93;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 4%;
    width: 15%;
    height: 2rem;
    justify-content: space-between;
}

.product__Quantity__Container:focus-within {
    border-color: #34A853;
}

.product__Quantity__Container input[type=number] {
    width: 60%;
    outline: none;
    border: none;
    text-align: center;
    -moz-appearance: textfield !important;
}

.product__Quantity__Container input[type=number]::-webkit-outer-spin-button,
.product__Quantity__Container input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.cs__Pointer {
    cursor: pointer;
}

.product__Modal__Container .product__Detail__Item .product__Details__Container .product__Description p {
    font-weight: 700;
    line-height: 50px;
    font-size: 18px;
}

.product__Modal__Container .product__Detail__Item .product__Details__Container .product__Description span {
    font-weight: 400;
    line-height: 30px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .product__Modal__Container {
        width: 60%;
        height: max-content;
    }

    .product__Modal__Container .product__Detail__Item .product__Details__Container {
        margin-bottom: 7%;
    }

    .product__Modal__Container .product__Detail__Item .product__Image__Container {
        padding-top: 6%;
        padding-bottom: 6%;
        margin-top: 1.8%;
    }
}

@media screen and (max-width: 767.98px) {
    .product__Modal__Container {
        width: 48%;
        height: max-content;
        padding-left: 1%;
        padding-right: 1%;
    }

    .product__Modal__Container .product__Detail__Item {
        flex-direction: column;
        margin-top: 4%;
    }

    .product__Modal__Container .product__Detail__Item .product__Image__Container,
    .product__Modal__Container .product__Detail__Item .product__Details__Container {
        width: 80%;
        margin: 0 auto;
    }

    .product__Modal__Container .product__Detail__Item .product__Details__Container {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .product__Modal__Container .product__Detail__Item .product__Details__Container .cart__Btn {
        padding: 4%;
        margin-bottom: 10%;
    }
}

@media screen and (max-width: 576px) {
    .product__Modal__Container {
        width: 40%;
    }
}

@media screen and (max-width: 520px) {
    .product__Modal__Container {
        width: 35%;
    }
}

@media screen and (max-width: 450px) {
    .product__Modal__Container {
        width: 30%;
    }

    .product__Modal__Container .product__Detail__Item .product__Image__Container,
    .product__Modal__Container .product__Detail__Item .product__Details__Container {
        width: 85%;
    }

    .product__Modal__Container .product__Detail__Item {
        margin-top: 8%;
        margin-bottom: 8%;
    }
}

@media screen and (max-width: 400px) {
    .product__Modal__Container {
        width: 28%;
    }
}

@media screen and (max-width: 350px) {
    .product__Modal__Container {
        width: 23%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideFromBottom {
    0% {
        transform: translate(-50%, -10%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}