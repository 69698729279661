.dash_nav_container {
    height: 100vh;
    position: relative;
    box-sizing: border-box;
    overflow: visible !important;
    display: flex;
    z-index: 100;
    display: flex;
    transition: width 1s ease-in-out;
}
.flex .absolute_dropdown_lists {
    width: 200px;
    position: absolute;
    height: auto;
    z-index: 10000000000;
    box-sizing: border-box;
    padding: 10px;
    left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #FCFCFC;
    border: 10px;
    box-shadow: 0px 0px 2.5rem 0rem rgba(82,63,105,0.1);
}
.dash_nav_container .dash_nav_contain {
    width: 88%;
    height: 100%;
    background: #FCFCFC;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    box-shadow: 3px 0px 2.5rem 0rem rgba(82,63,105,0.1);
}
.dash_nav_container .dash_nav_contain_full {
    width: 88%;
    height: 100%;
    background: #FCFCFC;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 2.5rem 0rem rgba(82,63,105,0.1);
}
.dash_nav_contain .dash_nav_content, .dash_nav_contain_full .dash_nav_content {
    width: 90%;
    box-sizing: border-box;
    height: 100%;
}
.dash_nav_content .dash_nav_top {
    width: 100%;
    overflow-x: hidden;
    height: 100px;
}
.dash_nav_top {
    display: flex;
    /*flex column initially*/
    justify-content: center;
    align-items: center;
}
.dash_nav_content .dash_navlists {
    width: 100%;
}
.dash_nav_content .dash_nav_bottom {
    float: bottom;
    bottom: 0px;
    position: absolute;
    box-sizing: border-box;
    margin-bottom: 1px;
    width: 100%;
}
.dash_nav_container .slider {
    float: right;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    top: 20px;
    position: absolute;
    /* z-index: 10000; */
    right: 3px;
    z-index: 1000000;
    cursor: pointer;
    border-radius: 50%;
    border-width: 5px;
    border: .5px solid #e2e8f0;
    background-color: #FCFCFC;
    box-shadow: 2px 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.dash_nav_top .brand {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    box-sizing: border-box;
    width: 95%;
    padding-right: 10px;
}
.brand > img {
    width: 25px;
    height: 25px;
}
.brand > span {
    font-size: 19px;
    font-weight: bold;
    padding-left: 5px;
    color: #34A853;
}
.dash_navlists > ul, .flex > ul {
    list-style: none;
}

.dash_navlists > ul > li {
    height: auto;
    padding: 10px;
    border-radius: 50%;
}
.dash_nav_bottom .dash_nav_logout {
    width: 100%;
}
li {
    display: flex;
    justify-content: center;
    align-items: center;
}

li .flex, li .flex-full {
    width: 100%;
}
.flex, .flex-full {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    height: auto;
    box-sizing: border-box;
    padding: 0px 10px;
    
    border-radius: 50%;
}
.flex .flex-link, .flex-full .flex-link {
    width: 100%;
    height: auto;
    /* background-color: red; */
}
/*update*/
li:hover .flex {
    /* background-color: #B9F0D2; */
    padding: 5px 0px;
}
li:hover .sidebarlist_img {
    width: 35px;
    height: 70px;
    padding-left: 10px;
}
.flex-full:hover .sidebarlist_img, .flex-full:hover .sidebar_list span {
    filter: invert(48%) sepia(80%) saturate(1000%) hue-rotate(86deg) brightness(50%);
} 
li .sidebar_list {
    width: 80%;
    height: 40px;
    padding-left: 0px;
    border-radius: 50%;
}
.sidebar_list .sidebarlist_img {
    width: 23px;
    height: 23px;
}
.dash_nav_logout .dash_logout {
    padding-left: 20px;
    width: 90%;
    height: 40px;
    
}
.flex ul li {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.none {
    display: none;
    visibility: none;
}
.flex .relative_dropdown_lists {
    width: 100%;
    font-size: 15px;
    height: auto;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-content: space-around;
    align-items: center;
    text-align: center;
}
.relative_dropdown_lists li {
    box-sizing: border-box;
    padding: 10px;
    height: auto;
}
.relative_dropdown_lists li .store_dropdown {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding-left: 20px;
}
.absolute_dropdown_lists li {
    font-size: 15px;
    width: 100%;
    height: auto;
    text-align: start;
    
}
.absolute_dropdown_lists li .dropdown_list {
    /* background-color: red; */
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
}
.absolute_dropdown_lists li:hover, .relative_dropdown_lists li:hover {
    color: #34A853;
}
.sidebar_list, .dash_logout {
    display: flex;  
    align-items: center;
}

.dash_logout img {
    width: 20px;
    height: 20px;
    
}

.sidebar_list span, .dash_logout span {
    font-size: 15px;
    padding-left: 15px;
}
/* .sidebar_list:hover span, .dash_logout:hover span {
    color: #34A853; 
} */
.sidebar_list:hover .sidebar_navlist_img, .dash_logout:hover .sidebar_navlist_img {
    filter: invert(48%) sepia(80%) saturate(1000%) hue-rotate(86deg) brightness(50%);
}

.dashboard__Icon{
    width: 20px;
    height: 20px;
    color: #545454;
}

@media screen and (max-width: 500px) {
    li .sidebar_list {
        padding-left: -8px;
    }
}
@media screen and (max-width: 500px) {
    .dash_nav_container {
        height: 130px;
        width: 100vw;
        overflow: visible;
        position: fixed;
        box-sizing: border-box;
        bottom: 0%;
        
        display: flex;
        justify-content: flex-end;
        backdrop-filter: blur(-20px) sepia(57%);
        /* z-index: 1000000000000; */
        z-index: 10;
    }
    .dash_nav_container .dash_nav_contain, .dash_nav_container .dash_nav_contain_full {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: none;
        box-shadow: none;
        
        z-index: 10000000000000;
    }
    .dash_nav_container .slider {
        display: none;
    }
    .absolute_dropdown_lists {
        display: none;
        visibility: none;
    }
    .dash_nav_contain .dash_nav_content, .dash_nav_contain_full .dash_nav_content {
        width: 100%;
        height: 80px;
        bottom: 0px;
        background-color: #ffffff;
        box-shadow: 4px 0px 5px 0 rgba(0, 0, 0, 0.2);
        
        
    }
    .dash_nav_top, .dash_nav_bottom {
        display: none;
    }
    .dash_nav_content .dash_navlists {
        width: 100%;
    }
    .dash_navlists > ul {
        width: 100%;
        
        height: auto;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        list-style: none;
    } 
    ul > li {
        height: 60px;
        width: 15%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    li .sidebar_list {
        padding-left: 15px;
    }
    .sidebar_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        justify-content: center;
        align-content: space-around;
    }
    .sidebar_list img, .dashboard__Icon {
        width: 15px;
        height: 15px;
    }
    .sidebar_list span {
        font-size: 13px;
        padding-top: 10px;
        padding-left: 0px;
    }
    li:nth-child(4) span, li:nth-child(5) span {
        font-size: 11px;
    }
    .relative_dropdown_lists {
        z-index: 100000000000000;
    }
    li .flex .relative_dropdown_lists {
        width: 80px;
        height: 60px;
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        backdrop-filter: blur(-2px) sepia(50%);
        
        bottom: 65px;
        background-color: #ffffff;
        border-radius: 10px 10px 10px 0px;
        box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.2), -2px 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
    li .flex ul li {
        display: flex;
        width: 100%;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 399px) {
    ul > li {
        height: 60px;
        width: 15%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    li .sidebar_list {
        width: 100%;
        padding-left: 0px;
    }
    .flex {
        padding-left: 0px;
    }
    .sidebar_list span {
        font-size: 11px;
    }
}