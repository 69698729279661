.indiv-profile-form{
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
}

.indiv-profile-form h1{
    color: #354661;
    padding-bottom: 20px;
}
.indiv-profile-form form input {
    width: 70%;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-family: inherit;
    padding-left: 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.indiv-profile-form form button{
    width: 250px;
    height: 40px;
    border-radius: 10px;
    outline: none;
    border: none;
    background: #34A853;
    color: white;
    font-family: inherit;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;

}

.Toastify__toast-body { font-family: 'Comfortaa'; }