.section_1 {
  position: absolute;
  left: 100px;
}

/* global logo styles*/

.landing_logo {
  width: 50px;
  height: 50px;
  position: absolute;
  top:90px;
}

.landing_name {
  width: 160px;
  height: 70px;
   position: absolute;
    top:80px;
    left:56px;
}

 .landing_header {
  position: absolute;
  left: 10px;
  top: 160px;
  word-spacing: 4px;
  width: 250px;
  font-size: 20px;
}

.home_logo{
  width:30px;
  height: 30px;
  position: absolute;
}

.home_name{
  width: 100px;
  height: 30px;
  position: absolute;
  left:33px;
}

.logo_wrapper{
  position: relative;
  top:20px;
  left:5%;
}

@media only screen and (min-width: 150px) and (max-width: 290px) {
  .home_name{
    display: none;
  }}

  @media only screen and (min-width: 100px) and (max-width: 149px) {
.home_name{
    display: none;}

    .home_logo{
      left:-19px;
    }


  }

/*end*/

/*display cards*/
.card {
  position: absolute;
  width: 180px;
  height: 170px;
  top: 200px;
  border-radius: 10px;
  font-size: 14px;
  /* background: red; */
}

.card h3 {
  color: white;
  font-weight: 200;
  letter-spacing: 3px;
  position: relative;
  top:25px;
}
.card p {
  color: rgba(40, 58, 87, 1);
  position: relative;
  top: 40px;
  font-style: normal;
  line-height: 15px;
  width: 150px;
}

.card p,.card h3{
    transform: translateX(20px);
} 

.card input[type=radio]{
    width: 50px;
    height: 20px;
    border: none;
    outline: none;
     position: relative;
    left:-10px;
    top:10px;
}
.land_bus {
  /* left: 200px; */
}

.land_indiv p{
  width: 200px;
  /* font-size: 10px; */
}

.land_indiv h3,.land_indiv p{
  left: -2px;
}

.land_indiv{
  width: 190px;
  left: 200px; 
}

/**end**/

.section_1 .proceed{
    margin-top: 400px;
    outline: none;
    border: none;
    background-color: #34A853;
    padding: 20px 125px 2px 130px;
    border-radius: 13px;
    color: white;
    /* text-align: center; */
    cursor: pointer;
}

.section_1 .proceed span{
    position: relative;
    bottom: 6px;
    left:-10px;
    bottom: 10px;
      font-size: 20px;
    letter-spacing: 2px;
} 

.section_1  .proceed img{
    transform: translate(-11px,-6px);
    height: 20px;
}

.landing_container .section_1_content #proceed a{
  color: black;
}

.section_1 #proceed{
  position: relative;
  top: 50px;
  left:-340px;
}

/**section 2 of landing page*/
/*banner styles --global styles*/
.section_2{
    background-color: rgba(40, 58, 87, 1);
    width: 50%;
/* transform: translateX(621px); */
height: 100%;
position: absolute;
right: 0;
}

.section_2 img{
    width: 500px;
    transform: translate(40px,160px);
}

.section_2 span{
position: absolute; 
font-style: normal;
font-weight: 400;
width:350px;
left:100px;
top:70px;
font-size: 40px;
line-height: 45px;
/* or 133% */
color: #FFFFFF;
}
