.layout-container p.layout-info{
  width: 80%;
  margin-top: -20px;
  color: #354661;
}

.layout-container .confirmation-wrapper{
    width: 80%;
    border-radius: 20px;
    background: #EDEDED;
    min-height: 500px;
    margin: 0 auto;
   }

.confirmation-footer span{
    color: #354661;
    font-size: 1rem;
}

.delivery-confirmation{
    padding-top: 30px;
}

.delivery-confirmation h3{
    color: #354661;
    font-weight: 400;
}


.delivered-products-container{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    left:0px; 
   min-height: 400px;
  }

   .delivered-products{
    display: flex;
    flex-wrap: wrap;
    min-width: 80%;
    position: absolute;
    left: 0px;
    height: auto;
    align-items: center;
    justify-content: center;
    margin: 0 10%;
       }

        .delivered-product{
        width: 30%;
        margin: 0px;
        box-sizing: border-box;
        border-radius:10px;
        transition: .4s;
        /* background-color: white; */
       }

       .delivered-products-container img{
        width: 100%;
    }

    .delivered-product img{
        width: 120px; 
        height: 120px;
        object-fit: contain;
    padding:10px;
    /* margin-left: -8px; */
    }

    .delivered-product-detail{
        margin-top: -10px;
      }

      .delivered-product-detail h5{
        font-weight: 300;
      }

      .delivery-confirmation button{
        border: none;
        outline: none;
        border-radius: 13px;
        width: 250px;
        color: white;
        height: 40px;
         background: #34A853;
         font-family: inherit;
         font-size: 1rem;
         margin-bottom: 10px;
      }

      .delivery-feedback{
        padding-top: 40px;
      }

      .delivery-feedback h3{
        color: #354661;
        font-size: 1.3rem;
        font-weight: 400;
    }

    .delivery-feedback textarea{
        margin-top: 20px;
        margin-left: 8%;
        margin-right: 10%;
        width: 70%;
        height: 300px;
        padding: 20px;
        font-family: inherit;
        border: none;
        outline: none;
        border-radius: 20px;
        padding-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .delivery-feedback button{
        border: none;
        outline: none;
        border-radius: 13px;
        width: 250px;
        color: white;
        height: 40px;
         background: #34A853;
         font-family: inherit;
         font-size: 1rem;
         margin-top: 20px;
    }