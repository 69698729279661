.business__Analytics__Card, 
.business__Analytics__Card .card__Info, 
.business__Analytics__Card .additional__Card__Info{
    display: flex;
    align-items: center;
}

.business__Analytics__Card {
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    padding: 1% 2%;
    font-size: 0.875rem;
    flex-direction: column;
    justify-content: center;
}

.business__Analytics__Card .card__Title{
    background-color: #B9F0D2;
    color: #283A57;
    border-radius: 25px;
    width: max-content;
    padding: 0.76% 4.2551%;
}

.business__Analytics__Card .card__Info{
    font-weight: 700;
    font-size: 2.1875rem;
    margin: 10% 0;
}

.business__Analytics__Card .card__Info >*:first-child{
    margin-right: 4%;
}

.business__Analytics__Card .card__Info .double__Icons__Container {
    position: relative;
}

.business__Analytics__Card .card__Info .double__Icons__Container > *:last-child{
    position: absolute;
    right: -20%;
    bottom: 20%;
    border-radius: 50%;
    font-size: 0.8rem;
    background-color: #fff;
    padding: 7%;
}

.business__Analytics__Card .additional__Card__Info {
    padding: 1%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.business__Analytics__Card hr {
    /* border: none; */
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    margin-bottom: 4%;
}

.business__Analytics__Card .additional__Card__Info:hover,
.business__Analytics__Card .additional__Card__Info:focus {
    color:  #34A853;
}

.business__Analytics__Card .additional__Card__Info > *:first-child {
    margin-left: 2%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .business__Analytics__Card{
        padding: 2%;
    }
}

@media screen and (max-width: 767.98px) {
    .business__Analytics__Card{
        padding: 4% 3%;
    }
}