.affliate__Analytics__Container {
    box-sizing: border-box;
    width: 100%;
    margin-top: 4%;
}

.affliate__Analytics__Container > * {
    padding-left: 2%;
}

.affliate__Analytics__Container .affliate__Analytics__Intro{
    width: 90%;
    margin: 4% auto;
}

.affliate__Analytics__Container .affliate__Analytics__Intro > * {
    display: inline-block;
    width: calc((100% - 10% - (3.5% * 2)) / 2);
}

.affliate__Analytics__Container .affliate__Analytics__Intro > *:first-child {
    margin-right: 3.5%;
}

.affliate__Analytics__Container .affliate__Analytics__Revenue__Overview{
    margin-bottom: 4%;
}


.affliate__Analytics__Revenue__Overview.first .overview__Intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97.4%;
    position: relative;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select{
    background-color: transparent;
    cursor: pointer;
    padding: 1.5% 2%;
    transition: 0.2s ease-in-out;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container{
    position: absolute;
    right: 0;
    /* bottom: -120px; */
    bottom: -470%;
    box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.25);
    padding: 1.5%;
    width: 10rem;
    background-color: #fff;
    border-radius: 10px;
    animation: fadeIn 0.4s ease-in-out;
}


.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form {
    animation: fadeIn 0.15s ease-in-out;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form > input[type=date] { 
    border: none;
    border: 1px solid #B9F0D2;
    font-family: 'Comfortaa', sans-serif;
    margin: 4% auto;
    padding: 2%;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form > input[type=date]::-webkit-datetime-edit { 
    padding: 1em; 
}
.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form > input[type=date]::-webkit-inner-spin-button { 
    display: none; 
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form .form__Icon{
    display: block;
    margin-bottom: 18%;
    color: #283A57;
    cursor: pointer;
    position: relative;
    left: -3%;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container.custom__Show form label{
    display: block;
    margin: 8% 0 2%;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Option, 
.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button{
    margin: 2% auto;
    padding: 6%;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Option:hover,
.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Option:focus,
.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button{
    background-color: #B9F0D2;
    border-radius: 10px;
    cursor: pointer;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button{
    border: none;
    font-family: 'Comfortaa', sans-serif;
    width: 100%;
    font-size: 15px;
    margin-top: 10%;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button:hover,
.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button:focus{
    filter: opacity(0.8);
    transition: 0.35s ease-in-out;
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button[disabled],
.affliate__Analytics__Revenue__Overview.first .overview__Intro .sales__Overview__Select__Options__Container .sales__Overview__Select__Button:disabled{
    cursor: not-allowed !important;
    filter: opacity(1);
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .dropdown__Icon{
    position: absolute;
    right: 0;
    color: #283A57;
    transition: 0.2s ease-in-out;
    transform: rotate(0deg);
}

.affliate__Analytics__Revenue__Overview.first .overview__Intro .dropdown__Icon.invert{
    transform: rotate(180deg);
}

.affliate__Analytics__Container .charts__Container{
    padding-right: 3%;
    margin: 5% 0;
}

.affliate__Analytics__Container .affliate__Analytics__Revenue__Overview .charts__Container .chart__Container{
    width: 100%;
}

.affliate__Analytics__Container .affliate__Referral__Count__Overview .charts__Container .referral__Count{
    margin: 2% 0;
}

.affliate__Analytics__Container .affliate__Referral__Count__Overview .charts__Container .chart__Container{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .affliate__Analytics__Container .charts__Container{
        padding-right: 4.8%;
    }
    .affliate__Analytics__Container .affliate__Analytics__Intro > * {
        margin-bottom: 6%;
    }
    .affliate__Analytics__Container .affliate__Referral__Count__Overview .charts__Container .referral__Count{
        margin-bottom: 5%;
    }
    .affliate__Analytics__Container .affliate__Referral__Count__Overview{
        margin-bottom: 10%;
    }
}

@media screen and (max-width: 767.98px) {
    .affliate__Analytics__Container > * {
        padding-left: 5%;
    }

    .affliate__Analytics__Container .affliate__Analytics__Intro {
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        height: 200px;
        white-space: nowrap;
    }
    .affliate__Analytics__Container .affliate__Analytics__Intro > * {
        width: 80%;
        height: 70%;
    }
    .affliate__Analytics__Container .affliate__Analytics__Revenue__Overview .charts__Container{
        overflow-x: scroll;
    }
    .affliate__Analytics__Container .affliate__Analytics__Revenue__Overview .charts__Container .chart__Container{
        width: 170%;
        margin-bottom: 7%;
    }
    .affliate__Analytics__Container .affliate__Referral__Count__Overview .charts__Container .chart__Container{
        width: 80%;
        margin-top: 9%;
    }
    .affliate__Analytics__Container .affliate__Referral__Count__Overview{
        margin-bottom: 50%;
    }
}