.section_proceed{
    position: relative;
    top: 140px;
}

.section_proceed .proceed{
    outline: none;
    border: none;
    background-color: #34A853;
    width: 100%;
     height:37px;
     position: relative;
     bottom: -18px;
    border-radius: 10px;
    color: white;
    text-align: center;
    cursor: pointer;
}

.section_proceed .proceed span{
    position: relative;
    bottom: 3px;
    left:-10px;
      font-size: 20px;
    letter-spacing: 2px;
} 

.section_proceed  .proceed img{
    transform: translate(-10px,1px);
    height: 20px;
}

.section_proceed label{
    font-size: 13px;
    position: relative; 
    left:5px;
}

.section_proceed label, .section_proceed input{
    position: relative;
    bottom: -13px;
}

.section_proceed span a{
    color: black;
}


.section_proceed span{
    font-size: 12px;
    position: relative;
    /* left:90px; */
    bottom: -17.9px;
}

.section_proceed #section_proceed{
     position: absolute;
    top:80px;
     font-size: 11px;
}