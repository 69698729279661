.newproduct_container {
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    /* background-color: white; */
    overflow-x: auto;
    margin-top: 10px;
    padding-top: 20px;
    margin-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;

}
.newproduct_container::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.newproduct_container h3 {
    font-size: 23px;
    font-weight: 600;
}
.newproduct_container .newproduct_wrapper {
    width: 100%;
    height: auto;
    /* overflow-x: auto; */
    background: #C4C4C44D;
    border-radius: 20px;
}
.newproduct_wrapper {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 40px;
    align-items: center;
}
.newproduct_wrapper .add-product-container {
    width: 50%;
    height: auto;
    box-sizing: border-box;
    
}
.add-product-container{
    
    margin: 60px auto;
    height: auto;
    
}
.upload-1 {
    display: none;
    visibility: none;
}

.add-product-container .input-1{
    margin-top:20px;
    width: 99%;
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    height: 50px;
    font-family: inherit;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.add-product-container .img-upload {
    margin-top:10px;
    width: 99%;
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    height: 90px;
    font-family: inherit;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.img-upload {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
}
.img-upload img {
    width: 20px;
    height: 20px;
}
.img-upload span {
    font-size: 15px;
    font-weight: 450;
    margin-top: 10px;
    text-align: center;
}
.add-product-container input::placeholder, .add-product-container textarea::placeholder {
    color: black;
    font-size: 17px;
    font-weight: 550;
}
.add-product-container .input-2{
    padding-top: 10px;
    margin-top: 20px;
    width: 99%;
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    height: 90px;
    font-family: inherit;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.add-product-container .product-type h5{
    float: left;
}

.add-product-container .product-type{
    width: 99%;
    height: auto;
    box-sizing: border-box;
    margin-top: 25px;
    padding-left: 10px;
}

.add-product-container .product-type .digital-radio-box{
    margin-left: 30px;
}
.product-type h5 {
    font-size: 18px;
    
}

.add-product-container .price-settings{
    width: 100%;
    margin-top: 30px;
    display: flex;
    
}

.add-product-container .price-settings select{
    width: max(70px, 10%);
    height: 50px;
    border-radius: 5px;
    background-color: white;
    margin-right: 20px;
    outline: none;
    border: none;
    padding-left: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.add-product-container .price-settings input{
    width: 75%;
    outline: none;
    box-sizing: border-box;
    height: 55px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.add-product-container .newproduct_base {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newproduct_base button {
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 20px;
    outline: none;
    background: #34A853;
    color: white;
    font-size: 15px;
    font-family: 'Comfortaa';
}
@media screen and (max-width: 999px) {
    .newproduct_wrapper .add-product-container {
        width: max(350px, 70%);
    }
}
@media screen and (max-width: 399px) {
    .newproduct_wrapper .add-product-container {
        width: 260px;
    }
    .img-upload span {
        font-size: 13px;
        font-weight: 450;
        margin-top: 10px;
        text-align: center;
    }
}
@media screen and (max-width: 499px) {
    .newproduct_container {
        margin-bottom: 80px;
    }
}