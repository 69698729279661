.email_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F6F5;
}
.email_container .email_wrapper {
    width: min(600px, 90%);
    height: 99%;
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.email_wrapper .brand {
    width: 100%;
}
.brand {
    height: 100px;
    box-sizing: border-box;
    display: flex;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
}
.email_wrapper .note_wrapper {
    width: 100%;
    height: 350px;
    display: flex;
    border-radius: 20px;
    background: #FFFFFF;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.email_wrapper .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
    margin-top: 20px;
}
.note_wrapper h3 {
    font-size: 21px;
    text-align: center;
}
.note_wrapper .span {
    margin-top: 35px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-content: space-around;
    padding: 0px 10px;
}
.span > span {
    font-size: 20px;
    line-height: 2rem;
    color: #354661;
    
    text-align: center;
}
.note_wrapper > button {
    width: 70%;
    height: 50px;
    background: #34A853;
    margin-top: 30px;
    border: none;
    outline:none;
    border-radius: 10px;
}
button > span {
    font-size: 18px;
    text-align: center;
    color: white;
    font-family: 'Comfortaa';
    
}
.bottom > span {
    font-size: 20px;
    text-align: center;
    margin-top: 5px;
}

@media screen and (max-width: 750px) {
    .span > span {
        font-size: 20px;
    }
    .bottom > span {
        font-size: 19px;
    }
}
@media screen and (max-width: 519px) {
    .span span, .bottom span {
        font-size: 15px;
        line-height: 1.5rem;
    }
    
}
@media screen and (max-width: 339px) {
    .span span, .bottom span {
        font-size: 13px;
    }
}



