.store-navbar {
    height: 180px;
}

.store-navbar-content {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.store-navbar-content i.fa {
    cursor: pointer;
}

.store-title .icons>* {
    cursor: pointer;
    font-size: 18px;
    cursor: pointer;
    margin-right: 2%;
    color: rgba(88, 90, 89, 0.86);
    ;
}

.store-title .icons {
    text-align: center;
    position: relative;
    left: -5px;
    top: 3px;
}

.store-title .store-img {
    text-align: center;
    margin: 0 auto;
}

.store-title img {
    margin-bottom: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.store-navbar i.question-icon {
    display: none;
}

.fa-question-circle {
    color: #979A93;
}

.store-title p {
    padding-top: 25px;
    font-size: 1.4rem;
}

.store-navbar-content a {
    cursor: pointer;
}

.store-content {
    cursor: pointer;
}

/* store footer*/
.store-footer {
    text-align: center;
    background-color: white;
    height: 40px;
    padding-top: 20px;
    /* position: fixed; */
    bottom: 0;
    width: 100%;
}

.store-footer img {
    width: 30px;
}

.footer-span span {
    position: relative;
    bottom: 10px;
    left: 10px;
}

.store-container {
    display: flex;
    flex-wrap: wrap;
    width: 62.5%;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    height: auto;
    padding-bottom: 20px;
}

.store-container>*.store-content {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 2%;
    width: calc((100% - 2%) /2);
}

.store-container>*.store-content:nth-child(2n + 2) {
    margin-right: 0;
}

.store-container .store-content {
    width: 32%;
    height: 320px;
    margin: 15px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
    /* padding-top: 0px; */
    transition: .4s;
    background-color: white;
}

.store-container .store-content span {
    padding-top: 10px;
    padding-bottom: 10px;
}

.store-navbar .store-navbar-content span .nav__Cart__Icon {
    font-size: 38px;
}

.store-container .store-content span:nth-child(2) {
    margin-left: 10px;
}

.store-container .store-content span:nth-child(3) {
    margin-right: 10px;
}

.store-content img {
    width: 100%;
    padding-top: 30px;
    height: 250px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    margin: 0 auto;
    display: block;
}

/* .store-content:hover{
        box-shadow: 0 0 11px rgba(33,33,33,.2);
        transform: translate(0px, -8px);
        transition: .6s;
        } */

.store-wrapper {
    text-align: center;
}

.store-wrapper form {
    position: relative;
    top: 40px;
    left: -5px;
}

.store-wrapper form i {
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
    color: #979A93;
}

.store-wrapper form input {
    width: 100%;
    border: none;
    border-radius: 5px;
    padding-left: 43px;
    font-family: inherit;
    outline: none;
    box-sizing: border-box;
    padding-top: 2.3%;
    padding-bottom: 2.3%;
    height: 53px;
}

.store-wrapper form input::placeholder {
    color: #979A93;
}

@media(max-width: 1000px) {
    .store-container .store-content {
        width: 65%;
    } .store-wrapper form {
        width: 80%;
    }

    .store-content img {
        max-width: 100%;
    }
}

.store-container {
    width: 100%;
}

@media(max-width: 400px) {
    .store-container .store-content {
        width: 80%;
    }

}

@media(max-width: 600px) {
    .store-wrapper form {
        left: -4px;
    }
}

@media(max-width: 350px) {
    .store-navbar-content h1 {
        /* font-size: 1rem; */
    }
}

.modal-container {
    animation: fadeIn 0.65s ease-in-out;
}

.modal-container button {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: white;
}

.modal-container button .close__Icon {
    position: absolute;
    right: 30px;
    font-size: 28px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    /* align-items:center; */
    justify-content: space-around;
}

.spinner-contatiner {
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #34A853;
}

.modal-content i.fa {
    padding: 8px;
    font-size: 23px;
    width: 25px;
    text-align: center;
    text-decoration: none;
    margin: 5px 7px;
    border-radius: 50%;
    color: white!important;
    background-color: #34a853!important;
}

.modal-content p {
    padding-top: 5px;
    font-size: .9rem;
    font-weight: 200;
}

@media screen and (max-width:320px) {
    .modal-content h4 {
        font-size: .9rem;
    }

    .modal-content p {
        font-size: .8rem;
    }
}

/****/

.cart-container {
    position: fixed;
    /* position: absolute; */
    top: 0;
    right: 0;
    width: 300px;
    justify-content: stretch;
    min-height: 1000px;
    /* height: 1145px; */
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    /* padding-bottom: 485px; */
    /* padding-left: 5px; */
    background-color: white;
    border-radius: 6px;
    box-shadow: -2px 0 4px hsl(0 4% 15%/10%);
    animation: slideFromRight 0.3s ease-in-out;
}


.cart-container img {
    width: 100%;
}

.cart-container img.empty {
    /* padding-top: 50px;
        margin-left: 0px;
       */
    padding-top: 50px;
    margin: 0 auto;
    width: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

div.full-section {
    position: absolute;
    top: 100px;
    right: 2px;
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.cart-container img.full {
    padding-top: 50px;
    text-align: center;
    width: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.cart-container div.empty-cart {
    margin-top: 24%;
}

.cart-container div.empty-cart span {
    font-family: 'Comfortaa', cursive;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 2rem;
    margin-left: 18%;
    line-height: 50px;
}

.cart-container button.last-btn {
    margin-left: 18%;
    margin-top: 25%;
    font-family: inherit;
    font-size: 1rem;
    height: 40px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    padding: 0 9.5%;
    transition: 0.35s ease-in-out;
}

.cart-items {
    display: grid;
    grid-template-columns: 32% 50% 18%;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.cart-item {
    display: grid;
    row-gap: 0.5rem;
}

.cart-item img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    padding: 10px;
    border: 2px solid black;

}


.cart-item hr {
    margin-top: -20px;
}

button.cart-checkout-btn {
    margin-top: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: inherit;
    font-size: 1rem;
    height: 40px;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #34A853;
    color: white;
}

/* checkout section */
.checkout-container {
    text-align: center;
}

.checkout-container h2 {
    padding-top: 20px;
    font-weight: 500;
}

.checkout-container hr {
    margin-top: 20px;
}

.checkout-container form input {
    margin-top: 10px;
    height: 40px;
    width: 90%;
    padding-left: 10px;
    font-family: inherit;
    border: none;
    outline: none;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.checkout-container form textarea {
    margin-top: 10px;
    height: 60px;
    width: 90%;
    padding-top: 10px;
    padding-left: 10px;
    font-family: inherit;
    border: none;
    outline: none;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
}

.checkout-container form textarea::placeholder,
.checkout-container form input::placeholder {
    color: black;
}

.checkout-container button {
    width: 90%;
    height: 40px;
    border-radius: 10px;
    margin-top: 15px;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: 1.1rem;
    color: white;
    cursor: pointer;
}


/**cart order details*/
.customer-order-details {
    min-height: 800px;
}

.customer-order-details button.contact-btn {
    width: 100%;
    height: 40px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #34A853;
    outline: none;
    color: black;
    font-family: inherit;
    cursor: pointer;
    font-size: .9rem;
    letter-spacing: .7px;
    margin-bottom: 10px;
}

.customer-order-details button.download-btn {
    width: 100%;
    height: 40px;
    background-color: #34A853;
    border-radius: 10px;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;
    cursor: pointer;
    font-size: .9rem;
    letter-spacing: .7px;
    margin-bottom: 10px;
}

.customer-order-details button.contact-btn a {
    color: white;
}

.customer-order-details p {
    /* letter-spacing: .5px; */
    line-height: 20px;
}

.customer-order-details p strong {
    /* font-weight: 400; */
}




/*end*/


.store-content-modal {
    display: flex;
    flex-direction: row;
}

.store-content-modal img {
    max-width: 100%;
    width: calc(100% - (1% * 2));
    padding: 4% 1%;
    height: 16rem;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.store-content-modal .modal-second-box {
    position: absolute;
    right: 80px;
}

.store-content-modal .modal-first-box {
    padding-top: 30px;
    height: auto;
    width: 370px;
    text-align: center;
    display: block;
    border-radius: 5px;
    margin-left: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.store-content-modal .modal-second-box hr {
    width: 30vw;
}

hr.first-hr-modal {
    margin-top: 10px;
}

hr.second-hr-modal {
    margin-top: 20px;
    margin-bottom: 20px;
}

.store-content-modal .modal-second-box h3 {
    font-weight: 300;
}

div.modal-add-to-cart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.modal-add-to-cart select {
    width: 80px;
    margin-top: 20px;
    border-radius: 5px;
}

div.modal-add-to-cart h3 {
    padding-top: 20px;
    font-size: 2rem;
    font-weight: 200;
}

.store-content-modal .modal-second-box .product-description {
    padding-top: 10px;
}

.store-content-modal .modal-second-box .product-description h3 {
    float: left;
    font-weight: 300;
}

.store-content-modal .modal-second-box .product-description span {
    /* font-size: .8rem; */
    padding-top: 10px;
    float: left;
}

button.modal-button {
    width: 100%;
    border-radius: 10px;
    height: 40px;
    font-family: inherit;
    font-size: 1rem;
    text-align: center;
    color: white;
    background: #34A853;
}

button.modal-button img {
    width: 20px;
}

.search__Container {
    width: 66%;
    margin: 0 auto;
    padding-left: 1.7%;
}

@keyframes slideFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}


@media screen and (max-width:400px) {
    .store-navbar-content {
        flex-direction: column;
    }

    .store-navbar-content h1 {
        font-size: 1.2rem;
    }

    .store-title p {
        display: none;
    }

    .store-navbar-content a {
        display: none;
    }

    .store-navbar i.question-icon {
        display: block;
        position: absolute;
        top: 10px;
        cursor: pointer;
        left: 5px;
    }

    .cart-container {
        width: 90%;
    }
}