.sales_container {
    width: 100%;
    /* height: 90%; */
    position: relative; 
}
.sales_container, .emptysales_container, .button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.emptysales_container {
    width: max(300px, 40%);
    height: 100%;
    
}
.emptysales_container .emptysales_wrapper {
    width: 90%;
    height: 95%;
}
.emptysales_wrapper {
    overflow-y: scroll; 
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.emptysales_wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.emptysales_wrapper .emptysales_content {
    width: 100%;
    height: auto;
}

.emptysales_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-around;
    align-items: center;
}
.emptysales_content >img {
    width: 150px;
    height: 150px;
}
.emptysales_content h2 {
    font-size: 18px;
    margin-top: 15px;
}
.emptysales_content > span {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}
.button {
    width: 200px;
    height: 45px;
    margin-top: 30px;
    background: #34A853;
    border-radius: 15px;
}
.button .button_content {
    width: 70%;
    height: auto;
}
.button_content {
    display: flex;
    justify-content: space-between;
}
.button_content span {
    font-size: 15px;
    color: white;
}
.button_content img {
    width: 15px;
    height: 10px;
    padding-top: 3px;
}

div.view-more{
    text-align: center;
}

div.view-more button{
    width: 40%;
    font-family: inherit;
    height: 40px;
    outline: none;
    border: none;
    background-color: #34A853;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 1.1rem;
}