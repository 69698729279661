.footer {
  background: #283A57;
  height: auto;
}

.foot1 img.logo{
width: 200px;
}
.dropdown {
  width: auto;
}
.foot1 {
  display: flex;
  padding-top: 5rem;
  margin-bottom: 3rem;
  justify-content: space-around;
}

.footcontents {
  /* margin-top: 8rem; */
  color: #fff;
  line-height: 35px;
}
.footcontents h1 {
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 25px;
}
.footcontents a {
  text-decoration: none;
  color: #fff;
}
.footcontents a:hover {
  text-decoration: none;
  color: #34A853;
}
.footcontents a h3 {
  font-weight: 400;
  font-size: 15px;
}
.footcontent {
  width: auto;
}
.footer #foottext {
  text-align: left;
  color: #606060;
  margin-top: 1rem;
  font-family: "Flutter";
}
@media screen and (max-width: 991px) {
  .firstbox {
    padding-bottom: 9rem;
  }

}
@media screen and (max-width: 767px) {
  .firstbox {
    padding-bottom: 3rem;
  }
  .firstimg {
    margin-top: 3rem;
  }
  .secondbutton {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .foot1 {
    display: grid;
    /* grid-template-columns: 50vw 50vw; */
    grid-column-gap: 1rem;
    margin-left: 3rem;
  }
  .socials {
  font-size: 10px;
}
}
@media screen and (max-width: 360px) {
  .socials {
  font-size: 7px;
}
}

.footer-bottom{
  background-color: #15253f;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-bottom p{
  font-size:  .8rem;
  font-style: normal;
  /* padding-bottom: 1rem; */

}

.footcontent  a i.fa {
  padding: 8px;
  font-size: 23px;
  width: 24px;
  text-align: center;
  text-decoration: none;
  margin: 5px 7px;
  border-radius: 50%;
  background-color:#fff;
}

.fa-twitter,.fa-facebook,.fa-instagram,.fa-linkedin{
    background-color: rgba(198, 189, 189, 1);
    color:rgba(34, 32, 32, 1);
}


@media screen and (max-width:500px) {
  .social-community{
    padding-top: 20px;
    margin-right: 30px;
  }

  .social-community h1{
    font-size: 1.1rem;
  }

  .foot1 img.logo{
    width: 100px;
    }
}
