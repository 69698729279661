.transaction_container {
    width: 99%;
    position: relative;
    height: 500px;
    margin-top: 50px;
    box-sizing: border-box;
    padding: 30px 0px;
    margin-left: 3px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.transaction_container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.transactions_content {
    display: flex;
    flex-direction: column;
    align-content: space-around;
}
.transaction_container .transactions_content {
    width: 95%;
    height: auto;
}
.transactions_content h1 {
    font-size: 23px;
    text-align: center;
}
.transactions_content .transactions {
    width: 100%;
    height: 90%;
    margin-top: 10px;
}
.transactions .skeleton {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    background: #D9D9D9;
}