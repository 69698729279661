.products_container {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding-top: 30px;
    overflow-x: auto;
 
    scrollbar-width: none;
    -ms-overflow-style: none;

    
}

.products_container::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.products_container .products_wrapper, .products_wrapper .products_top, .products_wrapper .products_main {
    width: 100%;
}
.products_container .products_wrapper {
    height: auto;
    /* overflow: hidden; */
    
}
.products_wrapper .products_main {
    height: auto;
    
}
.products_wrapper, .products_content, .product, .product_dropdown {
    display: flex;
    flex-direction: column;
    
    align-content: space-around;
}
.products_top, .product_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.products_top {
    height: 60px;
}
.products_top span {
    font-size: 25px;
    
    font-weight: bold;
}
.add_new_button, .products_main, .addnew_button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.addnew_button {
    width: 180px;
    height: 50px;
    background: #34A853;
    color: white;
    border-radius: 20px;
    font-size: 14px;
}
.products_main {
    background: #C4C4C44D;
    margin-top: 20px;
    border-radius: 20px;
    height: auto;
    box-sizing: border-box;
    padding: 30px 0px;
    
    /* scrollbar-width: none;
    -ms-overflow-style: none; */
}
/* .products_main::-webkit-scrollbar {
    width: 0;
    height: 0;
} */
.products_main .products_content {
    width: 95%;
    height: auto;
    padding-bottom: 15px;
}
.products_content .products_sort {
   width: 100%;
   height: 80px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}
.products_sort .products_sort_content {
    width: max(300px, 40%);
    height: 50px;
    display: flex;
    justify-content: space-around;
}
.products_sort_content img {
    width: 45px;
    height: 99%;
}
.products_sort_content >input {
    width: 70%;
    height: 90%;
    background: none;
    border-radius: 20px;
    border: 0.8px solid rgb(218, 215, 215);
    font-size: 18px;
    outline: none;
    color: rgba(205, 204, 204, 0.9);
    padding: 0px 20px;
}
input::placeholder {
    color: rgba(205, 204, 204, 0.9);
}
.products_content .allproducts {
    width: 100%;
    height: auto;
}
.allproducts {
    display: grid;
    height: auto;
   
    box-sizing: border-box;
    padding-right: 0px;
    grid-template-columns: 48% 48%;
    grid-gap: 4%;
    
}

.allproducts .product {
    height: 400px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
}
.product .dots {
    float: right;
    height: 10%;
    width: 97%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    /* font-size: 20px; */
    font-weight: bold;
}
.dots img {
    width: 50px;
    height: 95%;
}
.product .product_dropdown {
    position: absolute;
    height: 120px;
    width: 150px;
    margin-top: 30px;
    background: #FFFFFF;
    border: 1px solid #E0DBDB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 10px 10px;
}
.product_dropdown > span {
    
    display: flex;
    box-sizing: border-box;
    padding: 7px 10px;
    justify-content: flex-start;
    align-items: center;
}
.product .product_img {
    width: 98%;
    height: 80%;
    display: flex;
    justify-content: center;
    
}
.product_img .img {
    width: 100%;
    height: 100%;
    max-height: 95%;
}
.product .product_bottom {
    height: 10%;
    width: 98%;
    
}
.product_bottom {
    font-size: 15px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
}
.product_bottom span {
    font-size: 18px;
    padding: 0px 10px;
    box-sizing: border-box;
}
@media screen and (max-width: 799px) {
    .allproducts {
        display: flex;
        box-sizing: border-box;
        padding-left: 5px;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: space-around;
    }
    .allproducts .product {
        margin-top: 30px;
    }
}
@media screen and (max-width: 930px) {
    .product_bottom span {
        font-size: 16px;
    }
}
@media screen and (max-width: 599px) {
    .allproducts .product {
        height: 320px;
        
    }
}
@media screen and (max-width: 500px) {
    .products_main {
        margin-bottom: 70px;
    }
}