.indiv-profile-form form select.banks-select {
    width: 72%;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.indiv-profile-form form input[read-only],
.indiv-profile-form form input:read-only {
    /* filter: brightness(0.93); */
    cursor: default;
}