.site_input{
    position: relative;
    top:130px;
}

.site_input input{
     width:100%;
     /* width:400px; */
     height:45px;
     border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin: 6px 0;
  background: #DBD7D7;
  color: black;
  border: none;
  font-weight: 560;
    font-family: inherit;
}

.site_input input::placeholder{
  color: black;
  font-style: normal;
  /* position: relative;
  left:20px; */
  
}