/*max width 1079 banner becomes unstable*/


@media only screen and (min-width: 200px) and (max-width: 800px){
 .section_2{
    display: none;
}
}
@media only screen and (min-width: 200px) and (max-width: 300px) {

 
.section_1_content{
left: -50px;
    position: relative;
}

.section_1 .proceed{
    padding: 10px 143px 8px 143px;
}

.section_1 .proceed span{
    left:-40px;
    top:9px;
}

.section_1  .proceed img{
    transform: translate(28px,-12px);
}

.section_1 #proceed{
    left:40px;
}
}

@media only screen and (min-width: 301px) and (max-width: 400px) {

.section_1_content{
left: -75px;
    position: relative;
}

.section_1 .proceed{
 margin-top: 450px;
    width:270px;
}

.section_1 .proceed span{
      left:-70px;
    top:5px;
}

.section_1  .proceed img{
    transform: translate(25px,-16px);
}

.section_1 #proceed{
     position: absolute;
   top:103%;
   left:10%;
font-size: 13px;
}

/* 
   .section_2{
    width: 100%;
    height: 300px;
    top:590px;
} */

}


@media only screen and (min-width: 401px) and (max-width: 528px){


.section_1_content{
left: -77px;
    position: relative;
}

.section_1_content #proceed{
      position: absolute;
   top:103%;
   left:10%;
}

    /* .section_2{
    width: 100%;
    height: 50%;
    top:590px;
}

.section_2 img{
    width: 350px;
    transform: translate(4px,80px);
}

.section_2 span{
    top: 50px;
    left:40px;
    font-size:20px;
    width: 280px;
    line-height: 30px;
} */

.section_1 .proceed span{
  text-align: center;
}

.section_1  .proceed img{
   justify-content: center;
}


button.proceed{
    width: 380px;
    height: 60px;
}

}

@media only screen and (min-width: 529px) and (max-width: 800px){

.section_1_content{
left: -30px;
    position: relative;
}

    /* .section_2{
    width: 100%;
    height: 50%;
    top:590px;
}

.section_2 img{
    width:300px;
    transform: translate(4px,60px);
}

.section_2 span{
    top: 40px;
    left:40px;
    font-size:20px;
    width: 290px;
    line-height: 25px;
} */

.section_1_content #proceed{
   position: absolute;
   top:103%;
   left:10%;
}
}


@media only screen and (min-width: 801px) and (max-width: 899px){

.section_1_content{
left: -87px;
    position: relative;
}


.section_2 img{
    width: 400px;
    transform: translate(-5px,170px);
}

.section_2 span{
     left:40px;
}



}


@media only screen and (min-width: 900px) and (max-width: 1000px){
.section_1_content{
left: -60px;
    position: relative;
}

.section_2 img{
    width: 450px;
    transform: translate(-5px,170px);
}

.section_2 span{
     left:40px;
}
}

@media only screen and (min-width: 1001px) and (max-width: 1200px){
.section_2 img{
    width: 450px;
    transform: translate(-5px,170px);
}

.section_2 span{
     left:40px;
}

}

@media (max-height:588px){
    .section_2{
     height: 600px;
    }
}






