@media only screen and (min-width: 301px) and (max-width: 400px) {
    .card{
        width: 270px;
        height: 100px;
    }

    .land_indiv{
top: 330px;
left:1px;
}

.card h3 {
 top: -8px;
 left:7px;
}

.card p{
 top:1px;
 left:5px;
}
}