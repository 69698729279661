.confirm__Modal {
    text-align: center;
    width: 30%;
    padding-left: 0.5%;
    padding-right: 0.5%;
}
.confirm__Modal .confirm__Icon {
    color: #EA4335;
    font-size: 6rem;
}

.confirm__Modal .title {
    margin-top: 2%;
}

.confirm__Modal .confirm__Modal__Text {
    margin: 3% 0 7%;
}

.confirm__Modal .modal__Btns__Container {
    display: flex;
    justify-content: space-around;
    margin: 4% 0;
}

.confirm__Modal .modal__Btns__Container button {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 4% 7%;
    cursor: pointer;
    font-family: 'Comfortaa', cursive;
    color: #fff;
    transition: 0.35s ease-in-out;
}

.confirm__Modal .modal__Btns__Container button[disabled],
.confirm__Modal .modal__Btns__Container button:disabled {
    cursor: not-allowed;
}

.confirm__Modal .modal__Btns__Container .modal__Cancel__Btn {
    background-color: #BEBEBE;
}

.confirm__Modal .modal__Btns__Container .modal__Confirm__Btn {
    background-color: #34A853;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .confirm__Modal {
        width: 45%;
    }
}

@media screen and (max-width: 767.98px) {
    .confirm__Modal {
        width: 54%;
    }
}

@media screen and (max-width: 500px) {
    .confirm__Modal {
        width: 40%;
    }
}

@media screen and (max-width: 350px) {
    .confirm__Modal {
        width: 26%;
    }
}

@media screen and (max-width: 300px) {
    .confirm__Modal {
        width: 24%;
    }
}